// BlocksIDE is a project to create a complete js Blocks Development Platform
//
// Copyright 2016 Juan Carlos Orozco
//
// BlocksIDE was written by Juan Carlos Orozco and released under an Apache version 2 license.
//
// Git repositories for BlocksIDE are available at
//
// https://github.com/JC-Orozco/BlocksIDE
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

module.exports = function (Blockly, editor, API_HOST) {
  Blockly.JavaScript["bi_comment"] = function (block) {
    // TODO: Assemble JavaScript into code variable.
    var code = ""; // '...;\n';
    return code;
  };

  Blockly.JavaScript["bi_assignment"] = function (block) {
    var value_left = Blockly.JavaScript.valueToCode(
      block,
      "A",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_right = Blockly.JavaScript.valueToCode(
      block,
      "B",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_operator = block.getFieldValue("OP");
    var code = value_left + " " + text_operator + " " + value_right + "\n";
    return code;
  };

  Blockly.JavaScript["bi_assignment_return"] = function (block) {
    var value_left = Blockly.JavaScript.valueToCode(
      block,
      "A",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_right = Blockly.JavaScript.valueToCode(
      block,
      "B",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_operator = block.getFieldValue("OP");
    var code = value_left + " " + text_operator + " " + value_right;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["bi_math_arithmetic"] = function (block) {
    // Basic arithmetic operators, and power.
    var OPERATORS = {
      ADD: [" + ", Blockly.JavaScript.ORDER_ADDITION],
      MINUS: [" - ", Blockly.JavaScript.ORDER_SUBTRACTION],
      MULTIPLY: [" * ", Blockly.JavaScript.ORDER_MULTIPLICATION],
      DIVIDE: [" / ", Blockly.JavaScript.ORDER_DIVISION],
      POWER: [null, Blockly.JavaScript.ORDER_COMMA], // Handle power separately.
    };
    var tuple = OPERATORS[block.getFieldValue("OP")];
    var operator = " ";
    var order = Blockly.JavaScript.ORDER_ATOMIC;
    if (tuple) {
      operator = tuple[0];
      order = tuple[1];
    }
    var argument0 = Blockly.JavaScript.valueToCode(block, "A", order) || "0";
    var argument1 = Blockly.JavaScript.valueToCode(block, "B", order) || "0";
    var code;
    // Power in JavaScript requires a special case since it has no operator.
    if (!operator) {
      code = "Math.pow(" + argument0 + ", " + argument1 + ")";
      return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
    }
    code = argument0 + operator + argument1;
    return [code, order];
  };

  Blockly.JavaScript["bi_logic_compare"] = function (block) {
    // Comparison operator.
    var OPERATORS = {
      EQ: "==",
      NEQ: "!=",
      LT: "<",
      LTE: "<=",
      GT: ">",
      GTE: ">=",
    };
    var operator = OPERATORS[block.getFieldValue("OP")];
    var order =
      operator === "==" || operator === "!="
        ? Blockly.JavaScript.ORDER_EQUALITY
        : Blockly.JavaScript.ORDER_RELATIONAL;
    var argument0 = Blockly.JavaScript.valueToCode(block, "A", order) || "0";
    var argument1 = Blockly.JavaScript.valueToCode(block, "B", order) || "0";
    var code = argument0 + " " + operator + " " + argument1;
    return [code, order];
  };

  Blockly.JavaScript["bi_logic_operation"] = function (block) {
    // Operations 'and', 'or'.
    var operator = block.getFieldValue("OP") === "AND" ? "&&" : "||";
    var order =
      operator === "&&"
        ? Blockly.JavaScript.ORDER_LOGICAL_AND
        : Blockly.JavaScript.ORDER_LOGICAL_OR;
    var argument0 = Blockly.JavaScript.valueToCode(block, "A", order);
    var argument1 = Blockly.JavaScript.valueToCode(block, "B", order);
    if (!argument0 && !argument1) {
      // If there are no arguments, then the return value is false.
      argument0 = "false";
      argument1 = "false";
    } else {
      // Single missing arguments have no effect on the return value.
      var defaultArgument = operator === "&&" ? "true" : "false";
      if (!argument0) {
        argument0 = defaultArgument;
      }
      if (!argument1) {
        argument1 = defaultArgument;
      }
    }
    var code = argument0 + " " + operator + " " + argument1;
    return [code, order];
  };

  Blockly.JavaScript["bi_try_catch"] = function (block) {
    var statement_try = Blockly.JavaScript.statementToCode(block, "try");
    var statement_catch = Blockly.JavaScript.statementToCode(block, "catch");
    var statement_finally = Blockly.JavaScript.statementToCode(
      block,
      "finally"
    );
    var text_parameter = block.getFieldValue("parameter");
    var code =
      "try{\n" +
      statement_try +
      "\n} catch(" +
      text_parameter +
      "){\n" +
      statement_catch +
      "\n} finally{\n" +
      statement_finally +
      "}\n";
    return code;
  };

  Blockly.JavaScript["bi_catch"] = function (block) {
    var statement_catch = Blockly.JavaScript.statementToCode(block, "catch");
    var text_parameter = block.getFieldValue("parameter");
    var code = "catch(" + text_parameter + "){\n" + statement_catch + "}\n";
    return code;
  };

  Blockly.JavaScript["bi_throw"] = function (block) {
    var value_throw = Blockly.JavaScript.valueToCode(
      block,
      "throw",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = "throw " + value_throw + "\n";
    return code;
  };

  Blockly.JavaScript["bi_yield"] = function (block) {
    var value_yield = Blockly.JavaScript.valueToCode(
      block,
      "yield",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var checkbox_delegate = block.getFieldValue("delegate") === "TRUE";
    var code = "";
    if (checkbox_delegate) {
      code = "yield* ";
    } else {
      code = "yield ";
    }
    code += value_yield + "\n";
    return code;
  };

  Blockly.JavaScript["bi_yield_return"] = function (block) {
    var value_yield = Blockly.JavaScript.valueToCode(
      block,
      "yield",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var checkbox_delegate = block.getFieldValue("delegate") === "TRUE";
    var code = "";
    if (checkbox_delegate) {
      code = "yield* ";
    } else {
      code = "yield ";
    }
    code += value_yield;
    //return code;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["bi_export"] = function (block) {
    var statement_chain = Blockly.JavaScript.statementToCode(block, "export");
    return "export " + statement_chain;
  };

  Blockly.JavaScript["bi_import"] = function (block) {
    //  var value_import = Blockly.JavaScript.valueToCode(block, 'import', Blockly.JavaScript.ORDER_ATOMIC);
    var codeArr = new Array(block.itemCount_ - 1);
    for (var n = 1; n < block.itemCount_; n++) {
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var value_from = Blockly.JavaScript.valueToCode(
      block,
      "from",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = "import ";
    if (block.itemCount_ === 2) {
      code += codeArr[0];
    } else {
      code += "{" + codeArr.join(",") + "}";
    }
    code += " from " + value_from + "\n";
    return code;
  };

  Blockly.JavaScript["bi_import_as"] = function (block) {
    var text_import = block.getFieldValue("input");
    var text_as = block.getFieldValue("as");
    var code = text_import + " as " + text_as;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["bi_code_part"] = function (block) {
    var text_code = block.getFieldValue("code");
    var code = text_code;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["bi_code_line"] = function (block) {
    var text_code = block.getFieldValue("code");
    var code = text_code + "\n";
    return code;
  };

  Blockly.JavaScript["bi_access_field"] = function (block) {
    var value_variable = Blockly.JavaScript.variableDB_.getName(
      block.getFieldValue("variable"),
      Blockly.Variables.NAME_TYPE
    );
    var text_field = block.getFieldValue("field");
    var value_input = Blockly.JavaScript.valueToCode(
      block,
      "input",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = value_variable + "." + text_field + " = " + value_input + "\n";
    return code;
  };

  Blockly.JavaScript["bi_set_to"] = function (block) {
    var text_code = block.getFieldValue("code");
    var value_input = Blockly.JavaScript.valueToCode(
      block,
      "input",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = text_code + " = " + value_input + "\n";
    return code;
  };

  // Fix, init statements must be separated by a comma
  Blockly.JavaScript["bi_for"] = function (block) {
    Blockly.Generator.prototype.STATEMENT_PREFIX = ", ";
    var statement_init = Blockly.JavaScript.statementToCode(block, "init");
    Blockly.Generator.prototype.STATEMENT_PREFIX = null;
    var value_test = Blockly.JavaScript.valueToCode(
      block,
      "test",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    Blockly.Generator.prototype.STATEMENT_PREFIX = ", ";
    var statement_update = Blockly.JavaScript.statementToCode(block, "update");
    Blockly.Generator.prototype.STATEMENT_PREFIX = null;
    var statement_chain = Blockly.JavaScript.statementToCode(block, "chain");
    // g on this REGEX means match all ocurences
    statement_init = statement_init
      .replace(", ", "")
      .replace(/\n {2}/g, "")
      .trim();
    statement_update = statement_update
      .replace(", ", "")
      .replace(/\n {2}/g, "")
      .trim();
    var code =
      "for( " +
      statement_init +
      "; " +
      value_test +
      "; " +
      statement_update +
      " ){\n" +
      statement_chain +
      "}\n";
    return code;
  };

  Blockly.JavaScript["bi_for_in"] = function (block) {
    var text_var = block.getFieldValue("var");
    var value_array = Blockly.JavaScript.valueToCode(
      block,
      "array",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statement_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var code =
      "for(let " +
      text_var +
      " in " +
      value_array +
      "){\n" +
      statement_chain +
      "}\n";
    return code;
  };

  Blockly.JavaScript["bi_switch"] = function (block) {
    var value_switch = Blockly.JavaScript.valueToCode(
      block,
      "switch",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statement_default = Blockly.JavaScript.statementToCode(
      block,
      "default"
    );
    var codeArr = new Array(block.itemCount_ - 1);
    for (var n = 1; n < block.itemCount_; n++) {
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var code =
      "switch(" +
      value_switch +
      "){\n" +
      codeArr.join("\n") +
      "\ndefault: " +
      statement_default +
      "}\n";
    return code;
  };

  Blockly.JavaScript["bi_case"] = function (block) {
    var value_case = Blockly.JavaScript.valueToCode(
      block,
      "case",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var statement_st = Blockly.JavaScript.statementToCode(block, "statement");
    var code = "case " + value_case + ":" + statement_st; //+ '\n';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["bi_continue"] = function (block) {
    return "\ncontinue\n";
  };

  Blockly.JavaScript["bi_break"] = function (block) {
    return "\nbreak\n";
  };

  Blockly.JavaScript["bi_s1"] = function (block) {
    // Create a list with any number of elements of any type.
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_); // block.itemCount_);
    for (var n = 0; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n] = //Blockly.JavaScript.valueToCode(block, 'items' + n,
        //    Blockly.JavaScript.ORDER_COMMA) || 'null';
        Blockly.JavaScript.statementToCode(block, "items" + n) || "";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "\n  ." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code = "for(" + codeArr.join(", ") + "){" + chain + "}\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_call_statement"] = function (block) {
    // Create a list with any number of elements of any type.
    var text_name = block.getFieldValue("NAME");
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_ - 1);
    for (var n = 1; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code = text_name + "(" + codeArr.join(", ") + ")" + chain + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_call"] = function (block) {
    // Create a list with any number of elements of any type.
    var text_name = block.getFieldValue("NAME");
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_ - 1);
    for (var n = 1; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code = text_name + "(" + codeArr.join(", ") + ")" + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_direct_call_editable"] = function (block) {
    var value_function = Blockly.JavaScript.valueToCode(
      block,
      "function",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_ - 1); // block.itemCount_);
    for (var n = 1; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "\n  ." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code =
      "(" + value_function + ")(" + codeArr.join(", ") + ")" + chain + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_direct_call_editable_return"] = function (block) {
    var value_function = Blockly.JavaScript.valueToCode(
      block,
      "function",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_ - 1); // block.itemCount_);
    for (var n = 1; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "\n  ." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code = "(" + value_function + ")(" + codeArr.join(", ") + ")" + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_call_editable"] = function (block) {
    var text_name = block.getFieldValue("NAME");
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_ - 1); // block.itemCount_);
    for (var n = 1; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "\n  ." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code = text_name + "(" + codeArr.join(", ") + ")" + chain + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_call_editable_return"] = function (block) {
    var text_name = block.getFieldValue("NAME");
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var codeArr = new Array(block.itemCount_ - 1); // block.itemCount_);
    for (var n = 1; n < block.itemCount_; n++) {
      // code[n] = Blockly.JavaScript.valueToCode(block, 'ADD' + n,
      //     Blockly.JavaScript.ORDER_COMMA) || 'null';
      // TODO: Fix the naming on the AddSubGroup block and use code above
      codeArr[n - 1] =
        Blockly.JavaScript.valueToCode(
          block,
          "items" + n,
          Blockly.JavaScript.ORDER_COMMA
        ) || "null";
    }
    var chain = "";
    if (value_chain !== "") {
      chain = "\n  ." + value_chain.trim();
    }
    //var code = text_name.substr(1, text_name.length-2) + '(' + codeArr.join(', ') + ')' + chain;
    var code = text_name + "(" + codeArr.join(", ") + ")" + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_function_return"] = function (block) {
    //var text_name = block.getFieldValue('name');
    var function_type = block.getFieldValue("function_type");
    var text_name = block.getFieldValue("name");
    var text_args = block.getFieldValue("args");
    var statements_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var chain = statements_chain;
    var code = function_type + text_name + "(";
    code += text_args + "){\n" + chain + "}\n";

    //return [code, Blockly.JavaScript.ORDER_NONE];
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_function"] = function (block) {
    var text_name = block.getFieldValue("name");
    var function_type = block.getFieldValue("function_type");
    var text_args = block.getFieldValue("args");
    var statements_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var chain = statements_chain;
    var code = function_type + text_name + "(";
    code += text_args + "){\n" + chain + "}\n";
    return code;
  };

  Blockly.JavaScript["bi_return"] = function (block) {
    var value_ret = Blockly.JavaScript.valueToCode(
      block,
      "ret",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = "return " + value_ret + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_maps_set"] = function (block) {
    var text_name = block.getFieldValue("name");
    var text_val = block.getFieldValue("val");
    var statements_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var chain = statements_chain;
    var code = "set " + text_name + "(";
    code += text_val + "){\n" + chain + "}\n";

    //return [code, Blockly.JavaScript.ORDER_NONE];
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_maps_get"] = function (block) {
    var text_name = block.getFieldValue("name");
    var statements_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var chain = statements_chain;
    var code = "get " + text_name + "(";
    code += "){\n" + chain + "}\n";

    //return [code, Blockly.JavaScript.ORDER_NONE];
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_var"] = function (block) {
    var var_type = block.getFieldValue("var_type");
    var text_var = block.getFieldValue("var");
    var value_val = Blockly.JavaScript.valueToCode(
      block,
      "val",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = var_type + " " + text_var;
    if (value_val === "") {
      code += "\n";
    } else {
      code += " = " + value_val + "\n";
    }
    //return [code, Blockly.JavaScript.ORDER_NONE];
    return code;
  };

  Blockly.JavaScript["bi_var_name"] = function (block) {
    var text_name = block.getFieldValue("NAME");
    console.log("type inside blockly::::::::::", typeof Number(text_name));
    var code = `"${text_name}"`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_new"] = function (block) {
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var chain = value_chain.trim();
    var code = "new " + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_anonymous_class"] = function (block) {
    var text_name = block.getFieldValue("NAME");
    var text_extends = block.getFieldValue("extends");
    var statement_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var code = "class " + text_name;
    if (text_extends !== "") code += " extends " + text_extends;
    code += "{\n" + statement_chain + "}";
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_class"] = function (block) {
    var text_name = block.getFieldValue("NAME");
    var text_extends = block.getFieldValue("extends");
    var statement_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var code = "class " + text_name;
    if (text_extends !== "") code += " extends " + text_extends;
    code += "{\n" + statement_chain + "}\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_static"] = function (block) {
    var statement_chain = Blockly.JavaScript.statementToCode(block, "static");
    return "static " + statement_chain;
  };

  Blockly.JavaScript["bi_get"] = function (block) {
    var statement_chain = Blockly.JavaScript.statementToCode(block, "get");
    return "get " + statement_chain;
  };

  Blockly.JavaScript["bi_set"] = function (block) {
    var statement_chain = Blockly.JavaScript.statementToCode(block, "set");
    return "set " + statement_chain;
  };

  Blockly.JavaScript["bi_field"] = function (block) {
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_name = block.getFieldValue("NAME");
    var chain = "";
    if (value_chain !== "") {
      if (value_chain[0] === "[") {
        chain = value_chain.trim();
      } else {
        chain = "." + value_chain.trim();
      }
    }
    var code = text_name + chain + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_field_return"] = function (block) {
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_name = block.getFieldValue("NAME");
    var chain = "";
    if (value_chain !== "") {
      if (value_chain[0] === "[") {
        chain = value_chain.trim();
      } else {
        chain = "." + value_chain.trim();
      }
    }
    var code = text_name + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_string_return"] = function (block) {
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_name = block.getFieldValue("NAME");
    var chain = "";
    if (value_chain !== "") {
      if (value_chain[0] === "[") {
        chain = value_chain.trim();
      } else {
        chain = "." + value_chain.trim();
      }
    }
    var code = '"' + text_name + '"' + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_index"] = function (block) {
    var value_chain = Blockly.JavaScript.valueToCode(
      block,
      "chain",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_index = Blockly.JavaScript.valueToCode(
      block,
      "index",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var chain = "";
    if (value_chain !== "") {
      chain = "." + value_chain.trim();
    }
    var code = "[" + value_index + "]" + chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_adaptor"] = function (block) {
    var statements_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var chain = statements_chain.trim();
    var code = chain;
    //var code = statements_chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_statement"] = function (block) {
    var statements_chain = Blockly.JavaScript.statementToCode(block, "chain");
    var chain = statements_chain.trim();
    var code = chain;
    //var code = statements_chain;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_unary"] = function (block) {
    function isLetter(c) {
      return c.toLowerCase() !== c.toUpperCase();
    }
    var value_expression = Blockly.JavaScript.valueToCode(
      block,
      "expression",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_operator = block.getFieldValue("operator");
    if (isLetter(text_operator[0])) {
      text_operator += " ";
    }
    var code = text_operator + value_expression + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_unary_return"] = function (block) {
    function isLetter(c) {
      return c.toLowerCase() !== c.toUpperCase();
    }
    var value_expression = Blockly.JavaScript.valueToCode(
      block,
      "expression",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_operator = block.getFieldValue("operator");
    if (isLetter(text_operator[0])) {
      text_operator += " ";
    }
    var code = text_operator + value_expression;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_unary_postfix"] = function (block) {
    function isLetter(c) {
      return c.toLowerCase() !== c.toUpperCase();
    }
    var value_expression = Blockly.JavaScript.valueToCode(
      block,
      "expression",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_operator = block.getFieldValue("operator");
    if (isLetter(text_operator[0])) {
      text_operator += " ";
    }
    var code = value_expression + text_operator + "\n";
    //return [code, Blockly.JavaScript.ORDER_ATOMIC];
    return code;
  };

  Blockly.JavaScript["bi_unary_postfix_return"] = function (block) {
    function isLetter(c) {
      return c.toLowerCase() !== c.toUpperCase();
    }
    var value_expression = Blockly.JavaScript.valueToCode(
      block,
      "expression",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var text_operator = block.getFieldValue("operator");
    if (isLetter(text_operator[0])) {
      text_operator += " ";
    }
    var code = value_expression + text_operator;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return code;
  };

  Blockly.JavaScript["bi_spread"] = function (block) {
    var value_name = Blockly.JavaScript.valueToCode(
      block,
      "arg_array",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = "..." + value_name;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
    //return [code, Blockly.JavaScript.ORDER_NONE];
    //return code;
  };

  // Custom blocks

  // Blockly.JavaScript['api_call_block'] = function(block) {
  //   var url = Blockly.JavaScript.valueToCode(block, 'url', Blockly.JavaScript.ORDER_ATOMIC);

  //   var responseHandlerCode = Blockly.JavaScript.statementToCode(block, 'response_handler');

  //   var code = `
  //     // Make API call
  //     fetch(${url})
  //       .then(response => {
  //         // Handle response
  //         const responseCode = response.status;
  //         const responseData = response.json();

  //         ${responseHandlerCode}
  //       })
  //       .catch(error => {
  //         console.error('API call error:', error);
  //       });
  //   `;

  //   return code;
  // };

  Blockly.JavaScript["api_call_block"] = function (block) {
    // Get the predefined URL and body from the block
    var url = JSON.stringify(block.url);
    var body = Blockly.JavaScript.valueToCode(
      block,
      "body",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var responseHandlerCode = Blockly.JavaScript.statementToCode(
      block,
      "response_handler"
    );

    var code = `
    // Make POST API call
    fetch(${url}, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(${body})
    })
      .then(response => {
        // Handle response
        const responseCode = response.status;
        const responseData = response.json();
        
        ${responseHandlerCode}
      })
      .catch(error => {
        console.error('API call error:', error);
      });
  `;

    return code;
  };

  Blockly.JavaScript["response_code_block"] = function (block) {
    var code = "responseCode";
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["response_data_block"] = function (block) {
    var code = "responseData";
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["parent_connection"] = function (block) {
    var code = "responseData";
    console.log("block", block);
    console.log("editor", Blockly.ComponentManager.getComponent());
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript["custom_block_type"] = function (block) {
    console.log("block inside js generator", block);
    var text_name = block.getFieldValue("EDITOR_INSTANCE");
    var code = `${text_name}`;
    return code;
    //return code;
  };

  Blockly.JavaScript["to_number"] = function (block) {
    // Get the value from the input socket "VALUE"
    var value_value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to perform the type conversion
    var code = "Number(" + value_value + ")";

    // Return the converted value as output
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["to_string"] = function (block) {
    // Get the value from the input socket "VALUE"
    var value_value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to perform the type conversion
    var code = "String(" + value_value + ")";

    // Return the converted value as output
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["check_nan"] = function (block) {
    // Get the value from the input socket "VALUE"
    var value_value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to check if the value is NaN
    var code = "isNaN(" + value_value + ")";

    // Return the result as a boolean value
    return [code, Blockly.JavaScript.ORDER_LOGICAL_NOT];
  };

  Blockly.JavaScript["typeof"] = function (block) {
    // Get the value from the input socket "VALUE"
    var value_value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to get the typeof value
    var code = "typeof " + value_value;

    // Return the typeof value as a string
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["string_includes"] = function (block) {
    // Get the values from the input sockets "TEXT" and "SUBSTRING"
    var value_text = Blockly.JavaScript.valueToCode(
      block,
      "TEXT",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_substring = Blockly.JavaScript.valueToCode(
      block,
      "SUBSTRING",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to check if the string contains the substring
    var code = `${value_text}.includes(${value_substring})`;

    // Return the result as a boolean value
    return [code, Blockly.JavaScript.ORDER_LOGICAL_NOT];
  };

  Blockly.JavaScript["grapesjs_display"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.display = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_width"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.width = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_height"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.height = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_margin"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.margin = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_padding"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.padding = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_border"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.border = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_border_radius"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.borderRadius = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_color"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.color = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_background_color"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `editor.getSelected().getEl().style.backgroundColor = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["grapesjs_background_image"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    value = `url(${value})`;

    var code = `editor.getSelected().getEl().style.backgroundImage = ${value};\n`;

    return code;
  };

  Blockly.JavaScript["onclick_event"] = function (block) {
    var code = Blockly.JavaScript.statementToCode(block, "DO");

    // Generate JavaScript code to add the "on click" event listener.
    var eventHandler = `
    var selectedElement = editor.getSelected().getEl();
    if (selectedElement) {
      selectedElement.addEventListener('click', function(event) {
        ${code}
      });
    }
  `;

    return eventHandler;
  };

  Blockly.JavaScript["onchange_event"] = function (block) {
    var code = Blockly.JavaScript.statementToCode(block, "DO");

    // Generate JavaScript code to add the "on click" event listener.
    var eventHandler = `
    var selectedElement = editor.getSelected().getEl();
    if (selectedElement) {
      selectedElement.addEventListener('change', function(event) {
        ${code}
      });
    }
  `;

    return eventHandler;
  };

  Blockly.JavaScript["onmouseover_event"] = function (block) {
    var code = Blockly.JavaScript.statementToCode(block, "DO");

    // Generate JavaScript code to add the "on click" event listener.
    var eventHandler = `
    var selectedElement = editor.getSelected().getEl();
    if (selectedElement) {
      selectedElement.addEventListener('mouseover', function(event) {
        ${code}
      });
    }
  `;

    return eventHandler;
  };

  Blockly.JavaScript["onmouseout_event"] = function (block) {
    var code = Blockly.JavaScript.statementToCode(block, "DO");

    // Generate JavaScript code to add the "on click" event listener.
    var eventHandler = `
    var selectedElement = editor.getSelected().getEl();
    if (selectedElement) {
      selectedElement.addEventListener('mouseout', function(event) {
        ${code}
      });
    }
  `;

    return eventHandler;
  };

  Blockly.JavaScript["get_property_from_event_target"] = function (block) {
    var property = block.getFieldValue("PROPERTY");
    var code = `event.target.${property}`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["set_property_of_event_target"] = function (block) {
    var property = block.getFieldValue("PROPERTY");
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `event.target.${property} = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["set_css_property"] = function (block) {
    var property = Blockly.JavaScript.valueToCode(
      block,
      "PROPERTY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = `event.target.style[${property}] = ${value};\n`;
    return code;
  };

  Blockly.JavaScript["email_input"] = function (block) {
    var selectedComponent = editor.getSelected();

    var emailValue = "";

    if (
      selectedComponent &&
      selectedComponent.get("type") === "complete_form_wrapper"
    ) {
      var emailComponent = selectedComponent.findType("form_email_field")[0];

      console.log("emailComponent :::::::", emailComponent);

      if (emailComponent) {
        var inputComponent = emailComponent.components().at(1);
        console.log("inputComponent :::::::", inputComponent);

        if (
          inputComponent &&
          inputComponent.get("type") === "default" &&
          inputComponent.getAttributes().type === "email"
        ) {
          emailValue = inputComponent.getEl().value;
          console.log("emailValue :::::::", emailValue);
        }
      }
    }

    var code = `'${emailValue}'`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["text_input"] = function (block) {
    var selectedComponent = editor.getSelected();

    var textValue = "";

    if (
      selectedComponent &&
      selectedComponent.get("type") === "complete_form_wrapper"
    ) {
      var textComponent = selectedComponent.findType("form_text_field")[0];

      console.log("textComponent :::::::", textComponent);

      if (textComponent) {
        var inputComponent = textComponent.components().at(1);

        if (
          inputComponent &&
          inputComponent.get("type") === "default" &&
          inputComponent.getAttributes().type === "text"
        ) {
          textValue = inputComponent.getEl().value;
          console.log("textValue :::::::", textValue);
        }
      }
    }

    var code = `'${textValue}'`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["number_input"] = function (block) {
    var selectedComponent = editor.getSelected();

    var textValue = "";

    if (
      selectedComponent &&
      selectedComponent.get("type") === "complete_form_wrapper"
    ) {
      var textComponent = selectedComponent.findType("form_number_field")[0];

      console.log("textComponent :::::::", textComponent);

      if (textComponent) {
        var inputComponent = textComponent.components().at(1);

        if (
          inputComponent &&
          inputComponent.get("type") === "default" &&
          inputComponent.getAttributes().type === "number"
        ) {
          textValue = inputComponent.getEl().value;
          console.log("textValue :::::::", textValue);
        }
      }
    }

    var code = `'${textValue}'`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["password_input"] = function (block) {
    var selectedComponent = editor.getSelected();

    var textValue = "";

    if (
      selectedComponent &&
      selectedComponent.get("type") === "complete_form_wrapper"
    ) {
      var textComponent = selectedComponent.findType("form_password_field")[0];

      console.log("textComponent :::::::", textComponent);

      if (textComponent) {
        var inputComponent = textComponent.components().at(1);

        if (
          inputComponent &&
          inputComponent.get("type") === "default" &&
          inputComponent.getAttributes().type === "password"
        ) {
          textValue = inputComponent.getEl().value;
          console.log("textValue :::::::", textValue);
        }
      }
    }

    var code = `'${textValue}'`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["phone_input"] = function (block) {
    var selectedComponent = editor.getSelected();

    var textValue = "";

    if (
      selectedComponent &&
      selectedComponent.get("type") === "complete_form_wrapper"
    ) {
      var textComponent = selectedComponent.findType("form_phone_field")[0];

      console.log("textComponent :::::::", textComponent);

      if (textComponent) {
        var inputComponent = textComponent.components().at(1);

        if (
          inputComponent &&
          inputComponent.get("type") === "default" &&
          inputComponent.getAttributes().type === "tel"
        ) {
          textValue = inputComponent.getEl().value;
          console.log("textValue :::::::", textValue);
        }
      }
    }

    var code = `'${textValue}'`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["submit_button"] = function (block) {
    var event = block.getFieldValue("EVENT");
    var code = "";
    var actionCode = Blockly.JavaScript.statementToCode(block, "DO");

    if (event && actionCode) {
      code += `
      var submitButton = editor.getSelected().findType('form_submit_field')[0];
      if (submitButton) {
         var btn = submitButton.components().at(0)
        btn.getEl().addEventListener('${event}', function(event) {
          ${actionCode}
        });
      }
    `;
    }

    return code;
  };

  // Generate JavaScript code for the API call block
  // Blockly.JavaScript["api_call"] = function (block) {
  //   var method = block.getFieldValue("METHOD");
  //   var authentication = block.getFieldValue("AUTHENTICATION");

  //   var headers = Blockly.JavaScript.valueToCode(
  //     block,
  //     "HEADERS",
  //     Blockly.JavaScript.ORDER_ATOMIC
  //   );

  //   var payloadBlock = block.getInputTargetBlock("PAYLOAD");
  //   var payload = "";
  //   if (payloadBlock) {
  //     payload = Blockly.JavaScript.valueToCode(
  //       payloadBlock,
  //       "PAYLOAD",
  //       Blockly.JavaScript.ORDER_ATOMIC
  //     );
  //   }

  //   var code = "";

  //   // Construct the API call code based on method, authentication, headers, and payload
  //   code += "var options = { method: '" + method + "', headers: " + headers;

  //   if (authentication === "basic") {
  //     // Add code for basic authentication
  //     code +=
  //       ", auth: { username: 'your_username', password: 'your_password' }";
  //   } else if (authentication === "bearer") {
  //     // Add code for bearer token authentication
  //     code += ", headers: { 'Authorization': 'Bearer your_token' }";
  //   }

  //   if (payload) {
  //     code += ", body: " + payload;
  //   }

  //   code += " };\n";
  //   code += "fetch('your_api_url', options)";
  //   code += "  .then(response => response.json())";
  //   code += "  .then(data => console.log(data))";
  //   code += "  .catch(error => console.error('API Error: ', error));\n";

  //   return code;
  // };

  // Blockly.JavaScript["json_payload"] = function (block) {
  //   var keyValuePairs = Blockly.JavaScript.statementToCode(
  //     block,
  //     "KEY_VALUE_PAIRS"
  //   );

  //   // Generate JavaScript code to construct a JSON object
  //   var code = "{\n" + keyValuePairs + "\n}";

  //   return code;
  // };

  // Blockly.JavaScript["key_value_pair"] = function (block) {
  //   var key = Blockly.JavaScript.valueToCode(
  //     block,
  //     "KEY",
  //     Blockly.JavaScript.ORDER_ATOMIC
  //   );
  //   var value = Blockly.JavaScript.valueToCode(
  //     block,
  //     "VALUE",
  //     Blockly.JavaScript.ORDER_ATOMIC
  //   );

  //   // Generate JavaScript code for a single key-value pair
  //   var code = key + ": " + value + ",\n";

  //   return code;
  // };

  Blockly.JavaScript["create_variable"] = function (block) {
    var name = Blockly.JavaScript.valueToCode(
      block,
      "NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    ).replace(/'/g, "");
    var defaultValue = Blockly.JavaScript.valueToCode(
      block,
      "DEFAULT_VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    ).replace(/'/g, "");
    var type = Blockly.JavaScript.valueToCode(
      block,
      "TYPE",
      Blockly.JavaScript.ORDER_ATOMIC
    ).replace(/'/g, "");

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      body: JSON.stringify({
        project: window.location.search.split("=")[1],
        user: userInfo._id,
        name: name,
        defaultValue: defaultValue,
        typeOfVar: type,
        currentValue: defaultValue,
      }),
    };

    let api = `${API_HOST}temp_vars/create`;

    // Make the API call to create TempVariables
    return `fetch('${api}', ${JSON.stringify(config)})
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        alert('Error creating variable: ' + data.error);
      } else {
        alert('Variable added to database !!!');
        localStorage.setItem('tempVarInfo', JSON.stringify(data));
      }
    })
    .catch(error => {
      alert('Error: ' + error.message);
    });
  `;
  };

  Blockly.JavaScript["create_function"] = function (block) {
    var name = Blockly.JavaScript.valueToCode(
      block,
      "NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    ).replace(/'/g, "");
    var code = Blockly.JavaScript.valueToCode(
      block,
      "CODE",
      Blockly.JavaScript.ORDER_ATOMIC
    ).replace(/'/g, "");

    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let temVarRef = JSON.parse(localStorage.getItem("tempVarInfo"));

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
      body: JSON.stringify({
        project: window.location.search.split("=")[1],
        code: code,
        name: name,
        returnObj: temVarRef._id,
      }),
    };

    let api = `${API_HOST}custom_functions/create`;

    // Generate JavaScript code to create a function
    return `fetch('${api}', ${JSON.stringify(config)})
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        alert('Error creating function: ' + data.error);
      } else {
        alert('Function added to database !!!');
        localStorage.removeItem('tempVarInfo');
      }
    })
    .catch(error => {
      alert('Error: ' + error.message);
    });
  `;
  };

  Blockly.JavaScript["make_api_call"] = function (block) {
    var method = block.getFieldValue("METHOD");
    // var authentication = block.getFieldValue("AUTHENTICATION");
    var headers = Blockly.JavaScript.valueToCode(
      block,
      "HEADERS",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var payload = Blockly.JavaScript.valueToCode(
      block,
      "PAYLOAD",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var responseHandler = Blockly.JavaScript.statementToCode(
      block,
      "RESPONSE_HANDLER"
    );

    var code = `
    fetch(apiURL, {
      method: '${method}',
      headers: ${headers},
      body: ${payload}
    })
      .then(response => response.json())
      .then(data => {
        ${responseHandler}
      })
      .catch(error => {
        console.error('API call failed:', error);
      });
  `;

    return code;
  };

  Blockly.JavaScript["create_variable_from_local_storage"] = function (block) {
    var variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to store the value in local storage
    var code = `localStorage.setItem(${variableName}, ${value});\n`;
    return code;
  };

  Blockly.JavaScript["get_variable_from_local_storage"] = function (block) {
    var variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to retrieve the value from local storage
    var code = `localStorage.getItem(${variableName}) || ''`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["remove_variable_from_local_storage"] = function (block) {
    const variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    const code = `
    if (localStorage.getItem(${variableName})) {
      localStorage.removeItem(${variableName});
    }
  `;

    return code;
  };

  Blockly.JavaScript["create_session_variable"] = function (block) {
    var variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to store the value in session storage
    var code = `sessionStorage.setItem(${variableName}, ${value});\n`;
    return code;
  };

  Blockly.JavaScript["get_session_variable"] = function (block) {
    var variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to retrieve the value from session storage
    var code = `sessionStorage.getItem(${variableName}) || ''`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["remove_session_variable"] = function (block) {
    var variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to retrieve the value from session storage
    var code = `sessionStorage.removeItem(${variableName}) || ''`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["remove_session_variable"] = function (block) {
    const variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    const code = `
    if (sessionStorage.getItem(${variableName})) {
      sessionStorage.removeItem(${variableName});
    }
  `;

    return code;
  };

  Blockly.JavaScript["api_call"] = function (block) {
    const url = Blockly.JavaScript.valueToCode(
      block,
      "URL",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const method = block.getFieldValue("HTTP_METHOD");
    const headers = Blockly.JavaScript.statementToCode(block, "HEADERS");
    const payload = Blockly.JavaScript.statementToCode(block, "PAYLOAD");
    const userLogicSuccess = Blockly.JavaScript.statementToCode(
      block,
      "USER_LOGIC_SUCCESS"
    );
    const userLogicFailure = Blockly.JavaScript.statementToCode(
      block,
      "USER_LOGIC_FAILURE"
    );
    const responseVariable = Blockly.JavaScript.valueToCode(
      block,
      "RESPONSE_VARIABLE",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    let fetchCode = `
      fetch(${url}, {
        method: '${method}',
        headers: {
          ${headers}
        }`;

    if (method !== "GET") {
      fetchCode += `,
        body: JSON.stringify({${payload}})
      `;
    }

    fetchCode += `
      })
      .then(response => {
        // Check for successful response (status code 2xx)
        if (response.ok || !response.json().hasOwnProperty('message')) {
          return response.json();
        } else {
          // Handle non-successful response (status code not 2xx)
          throw new Error(\`API request failed with status: \${response.status}\`);
        }
      })
      .then(response_data => {
        ${responseVariable} = response_data;
        ${userLogicSuccess}
      })
      .catch(error => {
        console.error(error);
        // Handle errors, e.g., display an error message to the user
        ${userLogicFailure}
      });
    `;

    return fetchCode;
  };

  Blockly.JavaScript["api_call_V2"] = function (block) {
    const url = Blockly.JavaScript.valueToCode(
      block,
      "URL",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const method = block.getFieldValue("HTTP_METHOD");
    const isDefaultEndpoint = block.getFieldValue("CUSTOM_DEFAULT_ROUTE");
    const headers = Blockly.JavaScript.statementToCode(block, "HEADERS");
    let payload = Blockly.JavaScript.statementToCode(block, "PAYLOAD");
    const userLogicSuccess = Blockly.JavaScript.statementToCode(
      block,
      "USER_LOGIC_SUCCESS"
    );
    const userLogicFailure = Blockly.JavaScript.statementToCode(
      block,
      "USER_LOGIC_FAILURE"
    );
    const responseVariable = Blockly.JavaScript.valueToCode(
      block,
      "RESPONSE_VARIABLE",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    if (isDefaultEndpoint === "Yes") {
      payload = `
      
       "to_insert":{
       ${payload}
       }

      `;
    }

    let fetchCode = `
      fetch(${url}, {
        method: '${method}',
        headers: {
          ${headers}
        }`;

    if (method !== "GET") {
      fetchCode += `,
        body: JSON.stringify({${payload}})
      `;
    }

    fetchCode += `
      })
      .then(response => {
        // Check for successful response (status code 2xx)
        if (response.ok || !response.json().hasOwnProperty('message')) {
          return response.json();
        } else {
          // Handle non-successful response (status code not 2xx)
          throw new Error(\`API request failed with status: \${response.status}\`);
        }
      })
      .then(response_data => {
        ${responseVariable} = response_data;
        ${userLogicSuccess}
      })
      .catch(error => {
        console.error(error);
        // Handle errors, e.g., display an error message to the user
        ${userLogicFailure}
      });
    `;

    return fetchCode;
  };

  Blockly.JavaScript["response_status"] = function (block) {
    const response = Blockly.JavaScript.valueToCode(
      block,
      "RESPONSE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Check if the response status is OK (status code in the range 200-299)
    const code = `(${response} && ${response}.ok)`;

    return [code, Blockly.JavaScript.ORDER_LOGICAL_AND];
  };

  Blockly.JavaScript["header_block"] = function (block) {
    const key = Blockly.JavaScript.valueToCode(
      block,
      "HEADER_KEY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const value = Blockly.JavaScript.valueToCode(
      block,
      "HEADER_VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    return `${key}: ${value},`;
  };

  Blockly.JavaScript["header_block_v2"] = function (block) {
    const key = Blockly.JavaScript.valueToCode(
      block,
      "HEADER_KEY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const value = Blockly.JavaScript.valueToCode(
      block,
      "HEADER_VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    return `[${key}]: ${value},`;
  };

  Blockly.JavaScript["basic_auth"] = function (block) {
    var username = Blockly.JavaScript.valueToCode(
      block,
      "USERNAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var password = Blockly.JavaScript.valueToCode(
      block,
      "PASSWORD",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var resultVar = Blockly.JavaScript.valueToCode(
      block,
      "RES_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var code =
      resultVar +
      " = 'Basic ' + btoa(" +
      username +
      " + ':' + " +
      password +
      ");\n";

    return code;
  };

  Blockly.JavaScript["payload_block"] = function (block) {
    const key = Blockly.JavaScript.valueToCode(
      block,
      "PAYLOAD_KEY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const value = Blockly.JavaScript.valueToCode(
      block,
      "PAYLOAD_VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Construct the payload key-value pair without quotes for keys.
    return `${key}: ${value},`;
  };

  Blockly.JavaScript["payload_block_v2"] = function (block) {
    const key = Blockly.JavaScript.valueToCode(
      block,
      "PAYLOAD_KEY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const value = Blockly.JavaScript.valueToCode(
      block,
      "PAYLOAD_VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // If the key is a variable, it should be evaluated and used as a dynamic key
    return `[${key}]: ${value},`; // Using computed property syntax to evaluate the variable
  };

  // Blockly.JavaScript["response_status_block"] = function (block) {
  //   const responseStatusVariable = Blockly.JavaScript.variableDB_.getName(
  //     block.getFieldValue("RESPONSE_STATUS_VARIABLE"),
  //     Blockly.Variables.NAME_TYPE
  //   );
  //   return `${responseStatusVariable} = response.status;`;
  // };

  Blockly.JavaScript["nested_payload_block"] = function (block) {
    const someField = Blockly.JavaScript.valueToCode(
      block,
      "SOME_FIELD",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    const payloadPairs = Blockly.JavaScript.statementToCode(
      block,
      "PAYLOAD_PAIRS"
    );

    // Construct the payload key-value pairs inside "someField."
    return `${someField}: {
    ${payloadPairs}
  },`;
  };

  Blockly.JavaScript["handle_response"] = function (block) {
    const responseLogic = Blockly.JavaScript.statementToCode(
      block,
      "RESPONSE_LOGIC"
    );
    const responseVariable = Blockly.JavaScript.valueToCode(
      block,
      "RESPONSE_VARIABLE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    const handleResponseCode = `
    const ${responseVariable} = response_data;
    ${responseLogic}
  `;

    return handleResponseCode;
  };

  Blockly.JavaScript["get_response_variable"] = function (block) {
    const responseVariable = Blockly.JavaScript.valueToCode(
      block,
      "RESPONSE_VARIABLE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    return [responseVariable, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["iterate_array"] = function (block) {
    const array = Blockly.JavaScript.valueToCode(
      block,
      "ARRAY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const iterationLogic = Blockly.JavaScript.statementToCode(
      block,
      "ITERATION_LOGIC"
    );

    const iterateCode = `
    for (let i = 0; i < ${array}.length; i++) {
       ${variableName} = ${array}[i];
      ${iterationLogic}
    }
  `;

    return iterateCode;
  };

  Blockly.JavaScript["get_property"] = function (block) {
    const item = Blockly.JavaScript.valueToCode(
      block,
      "ITEM",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const property = Blockly.JavaScript.valueToCode(
      block,
      "PROPERTY",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate code to access the property value from the item
    const getPropertyCode = `${item}[${property}]`;

    return [getPropertyCode, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["parse_json"] = function (block) {
    const jsonString = Blockly.JavaScript.valueToCode(
      block,
      "JSON_STRING",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    const parseJsonCode = `
     ${variableName} = JSON.parse(${jsonString});
  `;

    return parseJsonCode;
  };

  Blockly.JavaScript["stringify_json"] = function (block) {
    const jsonObject = Blockly.JavaScript.valueToCode(
      block,
      "JSON_OBJECT",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const variableName = Blockly.JavaScript.valueToCode(
      block,
      "VARIABLE_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    const stringifyJsonCode = `
     ${variableName} = JSON.stringify(${jsonObject});
  `;

    return stringifyJsonCode;
  };

  Blockly.JavaScript["custom_logic_block"] = function (block) {
    // Generate JavaScript code for the custom logic block
    const innerLogic = Blockly.JavaScript.statementToCode(block, "INNER_LOGIC");

    // You can include your custom logic here
    const code = `
    // Custom logic block starts
    ${innerLogic}
    // Custom logic block ends
  `;

    return code;
  };

  Blockly.JavaScript["customize_component"] = function (block) {
    const componentType = Blockly.JavaScript.valueToCode(
      block,
      "COMPONENT_TYPE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const cssProperties = Blockly.JavaScript.statementToCode(
      block,
      "CSS_PROPERTIES"
    );

    // Generate JavaScript code to customize the component type
    const code = `
    var selectedComponent = editor.getSelected();
     console.log('selectedComponent1',selectedComponent);
    if (selectedComponent && selectedComponent.get("type") === "complete_form_wrapper") {
      console.log('selectedComponent',selectedComponent);
      var dynamicComp = selectedComponent.findType(${componentType})[0];
       console.log('dynamicComp1',dynamicComp);
      if (dynamicComp) {
        console.log('dynamicComp',dynamicComp);
        var innerComp = dynamicComp.getEl();
        console.log('innerComp',innerComp);
        ${cssProperties} // Apply CSS properties
      }
    }
  `;

    return code;
  };

  Blockly.JavaScript["css_property"] = function (block) {
    const propertyName = Blockly.JavaScript.valueToCode(
      block,
      "PROPERTY_NAME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const propertyValue = Blockly.JavaScript.valueToCode(
      block,
      "PROPERTY_VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    // Generate JavaScript code to set a CSS property and its value
    const code = `
    innerComp.style[${propertyName}] = ${propertyValue};
  `;

    return code;
  };

  Blockly.JavaScript["event_onClick"] = function (block) {
    const eventLogic = Blockly.JavaScript.statementToCode(block, "EVENT_LOGIC");

    // Generate JavaScript code for adding onClick event and logic
    const code = `
    innerComp.addEventListener("click", function(event) {
      ${eventLogic}
    });
  `;

    return code;
  };

  Blockly.JavaScript["event_onChange"] = function (block) {
    const eventLogic = Blockly.JavaScript.statementToCode(block, "EVENT_LOGIC");

    // Generate JavaScript code for adding onClick event and logic
    const code = `
    innerComp.addEventListener("click", function(event) {
      ${eventLogic}
    });
  `;

    return code;
  };

  // Define JavaScript code generation for js_block
  Blockly.JavaScript["js_block"] = function (block) {
    const events = Blockly.JavaScript.statementToCode(block, "EVENTS");

    const code = `
    // JavaScript logic
    ${events}
  `;

    return code;
  };

  Blockly.JavaScript["set_timeout"] = function (block) {
    const time = Blockly.JavaScript.valueToCode(
      block,
      "TIME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const handlerCode = Blockly.JavaScript.statementToCode(block, "HANDLER");

    const code = `
    setTimeout(function() {
      ${handlerCode}
    }, ${time})
  `;

    return code;
  };

  Blockly.JavaScript["set_interval"] = function (block) {
    const time = Blockly.JavaScript.valueToCode(
      block,
      "TIME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const handlerCode = Blockly.JavaScript.statementToCode(block, "HANDLER");

    const code = `
    setInterval(function() {
      ${handlerCode}
    }, ${time});
  `;

    return code;
  };

  Blockly.JavaScript["set_interval_new"] = function (block) {
    const time = Blockly.JavaScript.valueToCode(
      block,
      "TIME",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const intervalName = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    const handlerCode = Blockly.JavaScript.statementToCode(block, "HANDLER");

    const code = `
         ${intervalName} = setInterval(function() {
            ${handlerCode}
        }, ${time});
    `;

    return code;
  };

  Blockly.JavaScript["clear_interval"] = function (block) {
    const intervalName = block.getFieldValue("FUNCTION_NAME");

    const code = `
    
    if(typeof ${intervalName} === 'function'){
      clearInterval(${intervalName});
    }

    `;

    return code;
  };

  Blockly.JavaScript["concate_string"] = function (block) {
    var string1 = Blockly.JavaScript.valueToCode(
      block,
      "ITEM",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var variable = Blockly.JavaScript.valueToCode(
      block,
      "PROPERTY",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `${string1}  + ${variable} `;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["create_object"] = function (block) {
    const numberOfPairs = block.getFieldValue("PAIRS");

    let code = "{";

    for (let i = 1; i <= numberOfPairs; i++) {
      const keyBlock = block.getInputTargetBlock(`KEY${i}`);
      const valueBlock = block.getInputTargetBlock(`VALUE${i}`);

      // Get the generated code for the key and value blocks
      const keyCode = keyBlock
        ? Blockly.JavaScript.blockToCode(keyBlock)[0]
        : "";
      const valueCode = valueBlock
        ? Blockly.JavaScript.blockToCode(valueBlock)[0]
        : "";

      if (keyCode !== "" && valueCode !== "") {
        code += `${i > 1 ? ", " : ""}${keyCode}: ${valueCode}`;
      }
    }

    code += "}";
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["create_object_v_2"] = function (block) {
    const numberOfPairs = block.getFieldValue("PAIRS");

    let code = "{";

    for (let i = 1; i <= numberOfPairs; i++) {
      const keyBlock = block.getInputTargetBlock(`KEY${i}`);
      const valueBlock = block.getInputTargetBlock(`VALUE${i}`);

      // Get the generated code for the key and value blocks
      const keyCode = keyBlock
        ? Blockly.JavaScript.blockToCode(keyBlock)[0]
        : "";
      const valueCode = valueBlock
        ? Blockly.JavaScript.blockToCode(valueBlock)[0]
        : "";

      if (keyCode !== "" && valueCode !== "") {
        code += `${i > 1 ? ", " : ""}${keyCode}: ${valueCode}`;
      }
    }

    code += "}";
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["create_object_v_3"] = function (block) {
    const numPairs = Blockly.JavaScript.valueToCode(
      block,
      "NUM_PAIRS",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    let code = "{";

    for (let i = 1; i <= parseInt(numPairs, 10); i++) {
      const keyBlock = block.getInputTargetBlock(`KEY${i}`);
      const valueBlock = block.getInputTargetBlock(`VALUE${i}`);

      // Get the generated code for the key and value blocks
      const keyCode = keyBlock
        ? Blockly.JavaScript.blockToCode(keyBlock)[0]
        : "";
      const valueCode = valueBlock
        ? Blockly.JavaScript.blockToCode(valueBlock)[0]
        : "";

      if (keyCode !== "" && valueCode !== "") {
        code += `${i > 1 ? ", " : ""}${keyCode}: ${valueCode}`;
      }
    }

    code += "}";
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["timestamp"] = function (block) {
    const format = block.getFieldValue("FORMAT");
    const value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    let randomId = Math.floor(Math.random() * 1000);

    let code = "";
    if (format === "ist" && value) {
      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId} + 'Z' ;

      `;
    } else if (format === "utf" && value) {
      code = `
      // Convert value to Date object
      let dateObject${randomId} = new Date(${value});
      
      // Extract date and time components in UTC
      let year${randomId} = dateObject${randomId}.getUTCFullYear();
      let month${randomId} = String(dateObject${randomId}.getUTCMonth() + 1).padStart(2, "0");
      let day${randomId} = String(dateObject${randomId}.getUTCDate()).padStart(2, "0");
      let hours${randomId} = String(dateObject${randomId}.getUTCHours()).padStart(2, "0");
      let minutes${randomId} = String(dateObject${randomId}.getUTCMinutes()).padStart(2, "0");
      let seconds${randomId} = String(dateObject${randomId}.getUTCSeconds()).padStart(2, "0");
  
      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z' ;
  `;
    } else if (format === "timestamp" && value) {
      // Convert to Date object with timestamp format: 2024-01-23 17:35:12
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };

      let optionsString = JSON.stringify(options);

      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString(
        "en-US",
        ${optionsString}
      );

      newDateIst${randomId} = newDateIst${randomId}.replace(/[/]/g, "-");

      let parsedDate = new Date(newDateIst${randomId});

      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z' ;

      `;
    } else if (format === "regular" && value) {
      // Convert to Date object with regular format: 7 October 2020 13:05
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",

        hour12: false,
      };

      let optionsString = JSON.stringify(options);
      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString(
        "en-US",
        ${optionsString}
      );

      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z' ;

      `;
    } else if (format === "aest" && value) {
      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString("en-US", {
        timeZone: "Australia/Sydney",
      });
      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z';

      `;
    } else if (format === "acst" && value) {
      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString("en-US", {
        timeZone: "Australia/Adelaide",
      });
      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z';

      `;
    } else if (format === "awst" && value) {
      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString("en-US", {
        timeZone: "Australia/Perth",
      });
      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z';

      `;
    } else if (format === "thailand" && value) {
      code = `
      
      // Convert to Date object with IST format
      let newDateIst${randomId} = new Date(${value}).toLocaleString("en-US", {
        timeZone: "Asia/Bangkok",
      });
      let parsedDate${randomId} = new Date(newDateIst${randomId});

      // Extract date and time components
      let year${randomId} = parsedDate${randomId}.getFullYear();
      let month${randomId} = String(parsedDate${randomId}.getMonth() + 1).padStart(2, "0");
      let day${randomId} = String(parsedDate${randomId}.getDate()).padStart(2, "0");
      let hours${randomId} = String(parsedDate${randomId}.getHours()).padStart(2, "0");
      let minutes${randomId} = String(parsedDate${randomId}.getMinutes()).padStart(2, "0");
      let seconds${randomId} = String(parsedDate${randomId}.getSeconds()).padStart(2, "0");

      ${variable_result}= year${randomId} + '-' + month${randomId} + '-' + day${randomId} + 'T' + hours${randomId} + ':' + minutes${randomId} + ':' + seconds${randomId}+ 'Z';

      `;
    }

    return code;
  };

  Blockly.JavaScript["current date"] = function (block) {
    let code = `new Date()`;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["convert_to_date_object"] = function (block) {
    var valueVar = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );
    let code = `
    new Date(${valueVar})
    `;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["current dateV2"] = function (block) {
    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    let code = `
    ${variable_result}=new Date().toJSON()
    `;

    return code;
  };

  Blockly.JavaScript["local date"] = function (block) {
    let code = `new Date().toLocaleString()`;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["local dateV2"] = function (block) {
    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    let code = `
    let localDate=new Date().toLocaleString()
    ${variable_result}=new Date(localDate).toJSON()
    `;

    return code;
  };

  Blockly.JavaScript["utc date"] = function (block) {
    const value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    let code = `new Date(${value}).toUTCString()`;

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["utc dateV2"] = function (block) {
    const value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    let code = `
    let utcDate=new Date(${value}).toLocaleString()
    ${variable_result}=new Date(utcDate).toJSON()
    `;

    return code;
  };

  Blockly.JavaScript["dom_content_loaded_with_code"] = function (block) {
    // Generate JavaScript code for the domContentLoaded function with inner blocks.
    var code = 'document.addEventListener("DOMContentLoaded", function() {\n';
    code += Blockly.JavaScript.statementToCode(block, "INNER_BLOCKS");
    code += "});\n";
    return code;
  };

  // Define block code generator for "try-catch" block
  Blockly.JavaScript["try_catch_block"] = function (block) {
    var tryCode = Blockly.JavaScript.statementToCode(block, "TRY");
    var catchCode = Blockly.JavaScript.statementToCode(block, "CATCH");
    return "try {\n" + tryCode + "} catch (error) {\n" + catchCode + "}\n";
  };

  Blockly.JavaScript["formatnumber"] = function (block) {
    var format = block.getFieldValue("FORMAT");
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_NONE
    );

    var resVar = Blockly.JavaScript.valueToCode(
      block,
      "RESPONSE_VAR",
      Blockly.JavaScript.ORDER_NONE
    );

    let code;

    if (format === "ist") {
      code = `
       ${resVar}=Number(${value}).toLocaleString("en-IN")
      `;
    } else if (format === "international") {
      code = `
      ${resVar}=Number(${value}).toLocaleString("en-US")
     `;
    }

    return code;
  };

  Blockly.JavaScript["convert_to_number"] = function (block) {
    var value = Blockly.JavaScript.valueToCode(
      block,
      "VALUE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `Number(${value.replace(/,/g, "")})`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["convert_datetime"] = function (block) {
    var dropdown_type = block.getFieldValue("TYPE");
    var value_timestamp = Blockly.JavaScript.valueToCode(
      block,
      "TIMESTAMP",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    if (dropdown_type == "DATE") {
      return [
        `${value_timestamp}.split('T')[0]`,
        Blockly.JavaScript.ORDER_NONE,
      ];
    } else {
      return [
        `${value_timestamp}.split('T')[1].split('Z')[0]`,
        Blockly.JavaScript.ORDER_NONE,
      ];
    }
  };

  Blockly.JavaScript["increment_decrement_date"] = function (block) {
    var dropdown_operation = block.getFieldValue("OPERATION");
    var dateOrTime = block.getFieldValue("PART");
    var value_days_hours = Blockly.JavaScript.valueToCode(
      block,
      "DAYS_HOURS",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_date = Blockly.JavaScript.valueToCode(
      block,
      "DATE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    var code = "";
    if (dropdown_operation == "INCREMENT") {
      code = `
     let dummy${variable_result} = new Date(${value_date});
      `;
      if (dateOrTime === "days") {
        code += `
        dummy${variable_result}.setDate(dummy${variable_result}.getDate() + ${value_days_hours});
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      } else if (dateOrTime === "weeks") {
        code += `
        dummy${variable_result}.setDate(dummy${variable_result}.getDate() + ${
          value_days_hours * 7
        });
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      } else if (dateOrTime === "months") {
        code += `
        dummy${variable_result}.setMonth(dummy${variable_result}.getMonth() + ${value_days_hours});
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      } else if (dateOrTime === "year") {
        code += `
        dummy${variable_result}.setFullYear(dummy${variable_result}.getFullYear() + ${value_days_hours});
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      }
    } else {
      code = `
      let dummy${variable_result} = new Date(${value_date});
      `;
      if (dateOrTime === "days") {
        code += `
        dummy${variable_result}.setDate(dummy${variable_result}.getDate() - ${value_days_hours});
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      } else if (dateOrTime === "weeks") {
        code += `
        dummy${variable_result}.setDate(dummy${variable_result}.getDate() - ${
          value_days_hours * 7
        });
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      } else if (dateOrTime === "months") {
        code += `
        dummy${variable_result}.setMonth(dummy${variable_result}.getMonth() - ${value_days_hours});
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      } else if (dateOrTime === "year") {
        code += `
        dummy${variable_result}.setFullYear(dummy${variable_result}.getFullYear() - ${value_days_hours});
        ${variable_result}=dummy${variable_result}.toISOString()
        `;
      }
    }
    return code;
  };

  Blockly.JavaScript["increment_decrement_time"] = function (block) {
    var dropdown_operation = block.getFieldValue("OPERATION");
    var timeUnit = block.getFieldValue("PART");
    var value_time = Blockly.JavaScript.valueToCode(
      block,
      "DAYS_HOURS",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    var valueDate = Blockly.JavaScript.valueToCode(
      block,
      "DATE",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    var code = "";
    if (dropdown_operation == "INCREMENT") {
      code = `
      let dummy${variable_result} = new Date(${valueDate});
      `;
      if (timeUnit === "hours") {
        code += `
         dummy${variable_result}.setHours( dummy${variable_result}.getHours() + ${value_time});
        ${variable_result}= dummy${variable_result}.toISOString()
        `;
      } else if (timeUnit === "minutes") {
        code += `
        dummy${variable_result}.setMinutes( dummy${variable_result}.getMinutes() + ${value_time});
         ${variable_result}= dummy${variable_result}.toISOString()
        `;
      } else if (timeUnit === "seconds") {
        code += `
        dummy${variable_result}.setSeconds( dummy${variable_result}.getSeconds() + ${value_time});
         ${variable_result}= dummy${variable_result}.toISOString()
        `;
      }
    } else {
      code = `
      let dummy${variable_result} = new Date(${valueDate});
      `;
      if (timeUnit === "hours") {
        code += `
        dummy${variable_result}.setHours( dummy${variable_result}.getHours() - ${value_time});
         ${variable_result}= dummy${variable_result}.toISOString()
        `;
      } else if (timeUnit === "minutes") {
        code += `
        dummy${variable_result}.setMinutes( dummy${variable_result}.getMinutes() - ${value_time});
         ${variable_result}= dummy${variable_result}.toISOString()
        `;
      } else if (timeUnit === "seconds") {
        code += `
       dummy${variable_result}.setSeconds( dummy${variable_result}.getSeconds() - ${value_time});
         ${variable_result}= dummy${variable_result}.toISOString()
        `;
      }
    }
    return code;
  };

  Blockly.JavaScript["multilineText"] = function (block) {
    var textInput = block.getFieldValue("FIELDNAME");
    var variable_result = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    textInput = textInput
      .replace(/\\/g, "\\\\")
      .replace(/\n/g, "\\n")
      .replace(/'/g, "\\'")
      .replace(/"/g, '\\"');

    let code = `
    ${variable_result}="${textInput}"
    `;

    return code;
  };

  Blockly.JavaScript["add_line_break"] = function (block) {
    let code = '"\\n"';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript["merge_objects"] = function (block) {
    var value_obj1 = Blockly.JavaScript.valueToCode(
      block,
      "OBJ1",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var value_obj2 = Blockly.JavaScript.valueToCode(
      block,
      "OBJ2",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var mergedVar = Blockly.JavaScript.valueToCode(
      block,
      "Merged_Var",
      Blockly.JavaScript.ORDER_ASSIGNMENT
    );

    // Remove brackets and ';' from the objects and concatenate them
    var mergedCode =
      value_obj1.replace(/[{};]/g, "") +
      ", " +
      value_obj2.replace(/[{};]/g, "");

    // Wrap the merged code inside curly braces to form a single object
    return `
     ${mergedVar}= {${mergedCode}}
    `;
  };

  Blockly.JavaScript["store_session_time"] = function (block) {
    var code = `
    let projectIdForSessionUsage;

    if(window.location.href.includes("editor")){
      projectIdForSessionUsage =  window.location.href.split('=')[1]

      
    }else{
      projectIdForSessionUsage =window.location.pathname.split('large_preview/')[1]
      
    }
   let currentDateSession= new Date().toISOString();

   let keyNameForSessionUsage ="lastActionTime_" + projectIdForSessionUsage

   localStorage.setItem(keyNameForSessionUsage,JSON.stringify(currentDateSession))
   
    `;

    return code;
  };

  Blockly.JavaScript["is_session_expired"] = function (block) {
    var timeUnit = block.getFieldValue("PART");
    var value_time = Blockly.JavaScript.valueToCode(
      block,
      "DAYS_HOURS",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var successCode = Blockly.JavaScript.statementToCode(block, "SUCCESS");

    var code = "";

    if (timeUnit === "hours") {
      code += `

     let projectIdForSessionUsageExpiry;
      let keyToTurnOffIntervalAfterSessionLogic=false
  
      if(window.location.href.includes("editor")){
        projectIdForSessionUsageExpiry =  window.location.href.split('=')[1]
  
        
      }else{
        projectIdForSessionUsageExpiry =window.location.pathname.split('large_preview/')[1]
        
}
      
setInterval(function () {
  let keyNameForSessionUsageExpiry ="lastActionTime_" + projectIdForSessionUsageExpiry
  
  let grabbedTimeStampForExpiry = localStorage.getItem(keyNameForSessionUsageExpiry);

   if(grabbedTimeStampForExpiry){
     let parsedGrabbedTimeStampForExpiry= new Date(JSON.parse(grabbedTimeStampForExpiry));
     parsedGrabbedTimeStampForExpiry.setHours( parsedGrabbedTimeStampForExpiry.getHours() + ${value_time});
     let incrementedTimeStampForExpiry= parsedGrabbedTimeStampForExpiry.toISOString();
     let keyNameForSessionUsageExpiryUpdate ="lastActionTimeExpiry_" + projectIdForSessionUsageExpiry;
     localStorage.setItem(keyNameForSessionUsageExpiryUpdate,JSON.stringify(incrementedTimeStampForExpiry))
    
      let currentDateTimeForExpiry= new Date().toISOString()
      if(!keyToTurnOffIntervalAfterSessionLogic){
        if(currentDateTimeForExpiry>incrementedTimeStampForExpiry){
          ${successCode}
          keyToTurnOffIntervalAfterSessionLogic=true;
        }
      }
    
   }
      },2000)
  
     `;
    } else if (timeUnit === "minutes") {
      code += `

      let projectIdForSessionUsageExpiry;
      let keyToTurnOffIntervalAfterSessionLogic=false;
  
      if(window.location.href.includes("editor")){
        projectIdForSessionUsageExpiry =  window.location.href.split('=')[1]
  
        
      }else{
        projectIdForSessionUsageExpiry =window.location.pathname.split('large_preview/')[1]
        
      }

      setInterval(function(){
      let keyNameForSessionUsageExpiry ="lastActionTime_" + projectIdForSessionUsageExpiry
  
    let grabbedTimeStampForExpiry = localStorage.getItem(keyNameForSessionUsageExpiry);
  
     if(grabbedTimeStampForExpiry){
       let parsedGrabbedTimeStampForExpiry= new Date(JSON.parse(grabbedTimeStampForExpiry));
       parsedGrabbedTimeStampForExpiry.setMinutes( parsedGrabbedTimeStampForExpiry.getMinutes() + ${value_time});
       let incrementedTimeStampForExpiry= parsedGrabbedTimeStampForExpiry.toISOString();
       let keyNameForSessionUsageExpiryUpdate ="lastActionTimeExpiry_" + projectIdForSessionUsageExpiry;
       localStorage.setItem(keyNameForSessionUsageExpiryUpdate,JSON.stringify(incrementedTimeStampForExpiry))
       
        let currentDateTimeForExpiry= new Date().toISOString();

        if(!keyToTurnOffIntervalAfterSessionLogic){
          if(currentDateTimeForExpiry>incrementedTimeStampForExpiry){
            ${successCode}
            keyToTurnOffIntervalAfterSessionLogic=true;
          }
        }
       
     }
      },2000)
  
      
  
     `;
    }

    return code;
  };

  Blockly.JavaScript["remaining_session_time"] = function (block) {
    var resultDay = Blockly.JavaScript.valueToCode(
      block,
      "Res_Day",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var resultHour = Blockly.JavaScript.valueToCode(
      block,
      "Res_Hours",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var resultMin = Blockly.JavaScript.valueToCode(
      block,
      "Res_Minutes",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var resultSec = Blockly.JavaScript.valueToCode(
      block,
      "Res_Seconds",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var successCode = Blockly.JavaScript.statementToCode(block, "SUCCESS");

    var code = "";

    if (resultDay && resultHour && resultMin && resultSec) {
      code += `

      let projectIdForSessionUsageExpiry${resultMin};
   
  
      if(window.location.href.includes("editor")){
        projectIdForSessionUsageExpiry${resultMin} =  window.location.href.split('=')[1]
  
        
      }else{
        projectIdForSessionUsageExpiry${resultMin} =window.location.pathname.split('large_preview/')[1]
        
      }

      setInterval(function(){
      
       let keyNameForSessionUsageExpiryUpdate${resultMin} ="lastActionTimeExpiry_" + projectIdForSessionUsageExpiry${resultMin};
 
    let grabbedTimeStampForExpiryUpdate${resultMin} = localStorage.getItem(keyNameForSessionUsageExpiryUpdate${resultMin});
  
     if(grabbedTimeStampForExpiryUpdate${resultMin}){
       let parsedGrabbedTimeStampForExpiry${resultMin}= new Date(JSON.parse(grabbedTimeStampForExpiryUpdate${resultMin}));
       
        let currentDateTimeForExpiry${resultMin}= new Date()
        if(currentDateTimeForExpiry${resultMin}<parsedGrabbedTimeStampForExpiry${resultMin}){
          let difference${resultMin} = Math.abs(parsedGrabbedTimeStampForExpiry${resultMin} - currentDateTimeForExpiry${resultMin} ); 
    
    let days${resultMin} = Math.floor(difference${resultMin} / (1000 * 60 * 60 * 24));
    let hours${resultMin} = Math.floor((difference${resultMin} % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes${resultMin} = Math.floor((difference${resultMin} % (1000 * 60 * 60)) / (1000 * 60));
    let seconds${resultMin} = Math.floor((difference${resultMin} % (1000 * 60)) / 1000);

    ${resultDay}= days${resultMin};
    ${resultHour}= hours${resultMin};
    ${resultMin}=  minutes${resultMin};
    ${resultSec}= seconds${resultMin};
    
          ${successCode}
          
        }
      
     }

      },250)
  
     
  
     `;
    }

    return code;
  };

  Blockly.JavaScript["date_formats_to_ui"] = function (block) {
    var resultVar = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var dateInput = Blockly.JavaScript.valueToCode(
      block,
      "DATE_INPUT",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var dateFormat = block.getFieldValue("DATE_FORMAT");

    var code = "";

    code += `
      let splitDate${resultVar}=${dateInput}.split('Z')[0]
      let initDate${resultVar}=new Date(splitDate${resultVar});
      let ConvertedDate${resultVar}=initDate${resultVar}.toString();

      let fullMonth${resultVar} = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let monthName${resultVar} = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      let date${resultVar};
      if (ConvertedDate${resultVar}.includes('/')) {
          let parts = ConvertedDate${resultVar}.split(', ')[0].split('/');
          let datePart = parts[0];
          let monthPart = parts[1];
          let yearPart = parts[2];
          let timePart = ConvertedDate${resultVar}.split(', ')[1];
          let timeParts = timePart.split(':');
          date${resultVar} = new Date(yearPart, monthPart - 1, datePart, timeParts[0], timeParts[1], timeParts[2]);
      } else if (ConvertedDate${resultVar}.includes('T')) {
          date${resultVar} = new Date(ConvertedDate${resultVar});
      } else {
          date${resultVar} = new Date(ConvertedDate${resultVar});
      }

      if (isNaN(date${resultVar}.getTime())) {
          console.error("Invalid date format");
          window.alert("Invalid date format")
      }

      let day = date${resultVar}.getDate().toString().padStart(2, '0');
      let month = (date${resultVar}.getMonth() + 1).toString().padStart(2, '0');
      let year = date${resultVar}.getFullYear();
      let hours = date${resultVar}.getHours();
      let minutes = date${resultVar}.getMinutes().toString().padStart(2, '0');
      let amPm = hours >= 12 ? 'pm' : 'am';
      let displayHours = hours % 12 || 12;

      switch ("${dateFormat}") {
        case "DD MMM YYYY":
          ${resultVar} =  day +" " + monthName${resultVar}[date${resultVar}.getMonth()]+ " " + year;
          break;
        case "DD MMMM YYYY":
          ${resultVar} = day + " " + fullMonth${resultVar}[date${resultVar}.getMonth()] + " " + year;
          break;
        case "DD/MM/YYYY":
            ${resultVar} = day + "/" + month + "/" + year;
            break;
        case "MM/DD/YYYY":
            ${resultVar} = month + "/" + day + "/" + year;
            break;
        case "DD-MM-YYYY":
            ${resultVar} = day + "-" + month + "-" + year;
            break;
        case "YYYY-MM-DD":
          ${resultVar} = year + "-" + month + "-" + day;
          break;
        case "DD MMM YYYY HH:mm am/pm":
            ${resultVar} = day + " " + monthName${resultVar}[date${resultVar}.getMonth()] +" " + year +" "+ displayHours +":" +minutes +" "+ amPm;
            break;
        case "DD MMMM YYYY HH:mm am/pm":
            ${resultVar} = day + " " + fullMonth${resultVar}[date${resultVar}.getMonth()] +" "+ year + " " + displayHours +":"+minutes+" "+ amPm;
            break;
        case "DD/MM/YYYY HH:mm am/pm":
            ${resultVar} = day + " "+ "/"+month +"/" +year +" "+ displayHours +":" + minutes +" "+ amPm;
            break;
        case "MM/DD/YYYY HH:mm am/pm":
            ${resultVar} = month + "/" +day +"/"+year +" "+ displayHours+":"+minutes+" "+ amPm;
            break;
        case "DD-MM-YYYY HH:mm am/pm":
            ${resultVar} = day +"-"+ month +"-"+year+" "+ displayHours+":"+minutes+" "+ amPm;
            break;
        case "DD MMM YYYY 24:00":
            ${resultVar} = day+ " " + monthName${resultVar}[date${resultVar}.getMonth()] +" "+ year +" "+ hours+":"+minutes;
            break;
        case "DD MMMM YYYY 24:00":
            ${resultVar} = day +" "+ fullMonth${resultVar}[date${resultVar}.getMonth()] + " " + year +" " + hours+":"+minutes;
            break;
        case "DD/MM/YYYY 24:00":
            ${resultVar} = day+"/"+month+"/"+year+" "+ hours+":"+minutes;
            break;
        case "MM/DD/YYYY 24:00":
            ${resultVar} = month+"/"+day+"/"+year+" "+ hours+":"+minutes;
            break;
        case "DD-MM-YYYY 24:00":
            ${resultVar} = day+"-"+month+"-"+year+" "+ hours+":"+minutes;
            break;
        case "YYYY-MM-DD 24:00":
          ${resultVar} = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
          break;
        default:
            console.error("Invalid target date format");
            ${resultVar} = null;
      }
    `;

    return code;
  };

  Blockly.JavaScript["array_manipulation_method"] = function (block) {
    var resultVar = Blockly.JavaScript.valueToCode(
      block,
      "RESULT_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var searchKey = Blockly.JavaScript.valueToCode(
      block,
      "searchKey",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var searchValue = Blockly.JavaScript.valueToCode(
      block,
      "searchValue",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var arrayInput = Blockly.JavaScript.valueToCode(
      block,
      "ARRAY_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var methodRef = block.getFieldValue("METHOD");

    var code;

    if (methodRef === "find") {
      code += `
      ${resultVar}= ${arrayInput}.find((x)=>x[${searchKey}]===${searchValue});
      `;
    } else {
      code += `
      ${resultVar}= ${arrayInput}.filter((x)=>x[${searchKey}]===${searchValue});
      `;
    }

    return code;
  };

  Blockly.JavaScript["async_function_invoked"] = function (block) {
    var resData = Blockly.JavaScript.valueToCode(
      block,
      "RES_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var itemData = Blockly.JavaScript.valueToCode(
      block,
      "ITEM_REF",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var successCode = Blockly.JavaScript.statementToCode(block, "SUCCESS");

    var code = `
    
    (
      function(${itemData}){
        ${successCode}
      }
    )(${resData}[i])
    `;

    return code;
  };

  Blockly.JavaScript["async_function_mesh"] = function (block) {
    var resData = Blockly.JavaScript.valueToCode(
      block,
      "RES_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var successCode = Blockly.JavaScript.statementToCode(block, "SUCCESS");

    var code = `
    
   let ${resData}Dummy = ${successCode} ;

   ${resData}.push(${resData}Dummy)
    `;

    return code;
  };

  Blockly.JavaScript["async_function_promise"] = function (block) {
    var resData = Blockly.JavaScript.valueToCode(
      block,
      "RES_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var successCode = Blockly.JavaScript.statementToCode(block, "SUCCESS");

    var code = `
    
    Promise.all(${resData}).then(()=>{
      ${successCode}
    })
    `;

    return code;
  };

  Blockly.JavaScript["array_push_method"] = function (block) {
    var arrayVar = Blockly.JavaScript.valueToCode(
      block,
      "ARR_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var resData = Blockly.JavaScript.valueToCode(
      block,
      "RES_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = `
    
    ${arrayVar}.push(${resData})
   
    `;

    return code;
  };

  Blockly.JavaScript["grab_query_from_url"] = function (block) {
    var resVar = Blockly.JavaScript.valueToCode(
      block,
      "RES_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = `
     ${resVar}= window.top.location.search.split("?token=")[1];
    `;

    return code;
  };

  Blockly.JavaScript["grab_query_parameter_from_url"] = function (block) {
    var resVar = Blockly.JavaScript.valueToCode(block, "RES_VAR", Blockly.JavaScript.ORDER_ATOMIC);
    var queryParam = Blockly.JavaScript.valueToCode(block, "PARAM", Blockly.JavaScript.ORDER_ATOMIC);

    var code = `
      (function() {
        const params = new URLSearchParams(window.top.location.search);
        ${resVar} = params.get(${queryParam});
      })();
    `;

    return code;
  };

  Blockly.JavaScript["navigate_back_shorthand"] = function (block) {
    var code = `
    
    window.top.history.back()
    `;

    return code;
  };

  Blockly.JavaScript["set_last_page"] = function (block) {
    var code = `
    let currentPageRef= window.top.location.href
     localStorage.setItem('last_page_redsling_ref',currentPageRef);
    `;

    return code;
  };

  Blockly.JavaScript["manipulate_window_location"] = function (block) {
    var code = `
    let dummyLocation = localStorage.getItem('last_page_redsling_ref');
      window.top.location.href = dummyLocation ;
    `;

    return code;
  };

  Blockly.JavaScript["session_client_track"] = function (block) {
    var code = `

    let events_to_check_activity = {
  load: function() {
    let projectIdForSessionUsage;

    if(window.location.href.includes("editor")){
      projectIdForSessionUsage =  window.location.href.split('=')[1]

      
    }else{
      projectIdForSessionUsage =window.location.pathname.split('large_preview/')[1]
      
    }
   let currentDateSession= new Date().toISOString();

   let keyNameForSessionUsage ="lastActionTime_" + projectIdForSessionUsage

   localStorage.setItem(keyNameForSessionUsage,JSON.stringify(currentDateSession))
  },
  scroll: function() {
    let projectIdForSessionUsage;

    if(window.location.href.includes("editor")){
      projectIdForSessionUsage =  window.location.href.split('=')[1]

      
    }else{
      projectIdForSessionUsage =window.location.pathname.split('large_preview/')[1]
      
    }
   let currentDateSession= new Date().toISOString();

   let keyNameForSessionUsage ="lastActionTime_" + projectIdForSessionUsage

   localStorage.setItem(keyNameForSessionUsage,JSON.stringify(currentDateSession))
  },
  mousemove:function(){
  let projectIdForSessionUsage;

    if(window.location.href.includes("editor")){
      projectIdForSessionUsage =  window.location.href.split('=')[1]

      
    }else{
      projectIdForSessionUsage =window.location.pathname.split('large_preview/')[1]
      
    }
   let currentDateSession= new Date().toISOString();

   let keyNameForSessionUsage ="lastActionTime_" + projectIdForSessionUsage

   localStorage.setItem(keyNameForSessionUsage,JSON.stringify(currentDateSession))
  },
  click:function(){
  let projectIdForSessionUsage;

    if(window.location.href.includes("editor")){
      projectIdForSessionUsage =  window.location.href.split('=')[1]

      
    }else{
      projectIdForSessionUsage =window.location.pathname.split('large_preview/')[1]
      
    }
   let currentDateSession= new Date().toISOString();

   let keyNameForSessionUsage ="lastActionTime_" + projectIdForSessionUsage

   localStorage.setItem(keyNameForSessionUsage,JSON.stringify(currentDateSession))
  },
  input:function(){
  let projectIdForSessionUsage;

    if(window.location.href.includes("editor")){
      projectIdForSessionUsage =  window.location.href.split('=')[1]

      
    }else{
      projectIdForSessionUsage =window.location.pathname.split('large_preview/')[1]
      
    }
   let currentDateSession= new Date().toISOString();

   let keyNameForSessionUsage ="lastActionTime_" + projectIdForSessionUsage

   localStorage.setItem(keyNameForSessionUsage,JSON.stringify(currentDateSession))
  }
};
   
    
for (let event in events_to_check_activity) {
  window.document.addEventListener(event, events_to_check_activity[event]);
}
   
    `;

    return code;
  };

  Blockly.JavaScript["register_push_notification"] = function (block) {
    var code = `
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.register('/service.js').then(function(swReg) {
          console.log('Service Worker Registered', swReg);

          swReg.pushManager.getSubscription().then(function(subscription) {
            if (subscription === null) {
              swReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'YOUR_PUBLIC_VAPID_KEY'
              }).then(function(sub) {
                console.log('Subscribed!', sub);
              }).catch(function(err) {
                console.log('Failed to subscribe', err);
              });
            } else {
              console.log('Already subscribed', subscription);
            }
          });
        }).catch(function(error) {
          console.error('Service Worker Error', error);
        });
      } else {
        console.warn('Push messaging is not supported');
      }
    `;
    return code;
  };

  Blockly.JavaScript["send_push_notification"] = function (block) {
    var message = block.getFieldValue("message");
    var code = `
      fetch('/sendNotification', {
        method: 'POST',
        body: JSON.stringify({
          message: '${message}'
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    `;
    return code;
  };

  Blockly.JavaScript["grab_geolocation"] = function (block) {
    var status = Blockly.JavaScript.valueToCode(
      block,
      "STATUS",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var latVar = Blockly.JavaScript.valueToCode(
      block,
      "LAT_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var longVar = Blockly.JavaScript.valueToCode(
      block,
      "LONG_VAR",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `
      
        if (navigator.geolocation) {
                ${status} = 'pending';
                navigator.geolocation.getCurrentPosition(success${latVar}, error${latVar});
            } else {
                ${status} = 'Geolocation is not supported by your browser';
            }

             function success${latVar}(position) {
                ${latVar} = position.coords.latitude;
                ${longVar} = position.coords.longitude;

                ${status} = 'success';
            }

            function error${latVar}() {
                ${status} = 'error';
            }


    `;
    return code;
  };

  Blockly.JavaScript["calc_distance_of_geo"] = function (block) {
    var latA = Blockly.JavaScript.valueToCode(
      block,
      "LAT_1",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var longA = Blockly.JavaScript.valueToCode(
      block,
      "LONG_1",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var latB = Blockly.JavaScript.valueToCode(
      block,
      "LAT_2",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var longB = Blockly.JavaScript.valueToCode(
      block,
      "LONG_2",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var resVar = Blockly.JavaScript.valueToCode(
      block,
      "Res_var",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var code = `
      
  const R${resVar} = 6371; 
  const dLat${resVar} = toRad${resVar}(${latB} - ${latA});
  const dLon${resVar} = toRad${resVar}(${longB} - ${longA});
  const radLat1${resVar} = toRad${resVar}(${latA});
  const radLat2${resVar} = toRad${resVar}(${latB});

  const a${resVar} = Math.sin(dLat${resVar} / 2) * Math.sin(dLat${resVar} / 2) +
            Math.sin(dLon${resVar} / 2) * Math.sin(dLon${resVar} / 2) * Math.cos(radLat1${resVar}) * Math.cos(radLat2${resVar});
  const c${resVar} = 2 * Math.atan2(Math.sqrt(a${resVar}), Math.sqrt(1 - a${resVar}));
  ${resVar}= R${resVar} * c${resVar};
 

function toRad${resVar}(value) {
  return value * Math.PI / 180;
}

    `;
    return code;
  };

  Blockly.JavaScript["random_variable_generator"] = function (block) {
    // Check if the variable name is already stored in the block
    if (!block.variableName) {
      // Generate a unique variable name using Blockly's variable management
      block.variableName = Blockly.Variables.generateUniqueName(
        block.workspace
      );

      // Register the new variable with Blockly's system
      block.workspace.createVariable(block.variableName);
    }

    // Generate a random value for this variable (e.g., an integer between 1 and 100)
    var randomValue = Math.floor(Math.random() * 100) + 1;

    // Create the JavaScript code to define the variable and assign the value
    var code = "var " + block.variableName + " = " + randomValue + ";\n";

    return code;
  };

  Blockly.JavaScript["nearest_matching_day"] = function (block) {
    var date = Blockly.JavaScript.valueToCode(
      block,
      "DATE",
      Blockly.JavaScript.ORDER_ATOMIC
    );
    var day = Blockly.JavaScript.valueToCode(
      block,
      "DAY",
      Blockly.JavaScript.ORDER_ATOMIC
    );

    var code = `
(function() {
    var givenDate = new Date(${date});
    var dayInput = ${day}.toLowerCase();
    var daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    var targetDayIndex = daysOfWeek.indexOf(dayInput);
    if (targetDayIndex === -1) {
        throw new Error("Invalid day of the week: " + dayInput);
    }
    var currentDayIndex = givenDate.getDay();
    var diff = targetDayIndex - currentDayIndex;

    if (diff === 0) {
        return givenDate;
    } else if (diff > 0) {
        givenDate.setDate(givenDate.getDate() + diff);
    } else {
        givenDate.setDate(givenDate.getDate() + 7 + diff);
    }

    return givenDate;
})()
    `;

    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  // Blockly.JavaScript["practice_block_a"] = function (block) {
  //   // var val1 = block.getFieldValue("VAL1");
  //   // var val2 = block.getFieldValue("VAL2");

  //   var val1 = Blockly.JavaScript.valueToCode(block, "VAL1", Blockly.JavaScript.ORDER_ATOMIC);
  //   var val2 = Blockly.JavaScript.valueToCode(block, "VAL2", Blockly.JavaScript.ORDER_ATOMIC);

  //   var code = `${val1} + ${val2}`;

  //   return [code, Blockly.JavaScript.ORDER_ADDITION];
  // };

  // block to return the substring at the index mentioned in the splitted array of strings using split('')
  Blockly.JavaScript['text_split_substring'] = function(block) {
    var inputString = Blockly.JavaScript.valueToCode(block, 'INPUT_STRING', Blockly.JavaScript.ORDER_ATOMIC);
    var substring = block.getFieldValue('SUBSTRING');
    var index = block.getFieldValue('INDEX');
    
    var code = `(${inputString} && typeof ${inputString} === 'string' && ${inputString}.trim() !== '' ? ${inputString}.split('${substring}')[${index}] : '')`;
    
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  // // block-code to convert a number into different formats
  // Blockly.JavaScript["number_formats"] = function (block) {
  //   let formatType = block.getFieldValue("FORMAT");
  //   let num = Blockly.JavaScript.valueToCode(block, "NUMBER", Blockly.JavaScript.ORDER_ATOMIC);

  //   // Ensure the number is correctly passed as a string
  //   var code = `
  //     (function(input, formatType) {
  //       // Remove all non-digit characters except decimal point
  //       function removeSeparators(str) {
  //         return str.replace(/[^\d.]/g, "");
  //       }

  //       function addSeparator(number, separator, groupSize) {
  //         const parts = number.split(".");
  //         const integerPart = parts[0];
  //         const decimalPart = parts[1] ? "." + parts[1] : "";

  //         const regex = new RegExp('(?<=\\d)(?=(\\d{' + groupSize + '})+(?!\\d))', 'g');
  //         return integerPart.replace(regex, separator) + decimalPart;
  //       }

  //       function formatIndianNumberSystem(number) {
  //         const parts = number.split(".");
  //         const integerPart = parts[0];
  //         const decimalPart = parts[1] ? "." + parts[1] : "";

  //         const firstGroup = integerPart.slice(-3);
  //         const remainingGroups = integerPart.slice(0, -3).replace(/(\\d)(?=(\\d{2})+(?!\\d))/g, "$1,");
  //         return remainingGroups + (remainingGroups ? "," : "") + firstGroup + decimalPart;
  //       }

  //       // Ensure input is string, whether it’s a number or a string
  //       if (typeof input === 'number') {
  //         input = input.toString();
  //       }

  //       // Handle each format case
  //       switch (formatType) {
  //         case "no_sep":
  //           return removeSeparators(input);
  //         case "aps_sep":
  //           return addSeparator(input, "'", 3);
  //         case "spc_sep":
  //           return addSeparator(input, " ", 3);
  //         case "spc_sep_underscore":
  //           return addSeparator(input, "_", 3);
  //         case "wes_int_frt":
  //           return addSeparator(input, ",", 3);
  //         case "eu_frt":
  //           return addSeparator(input, ".", 3).replace(/\./g, ",");
  //         case "ind_num_sys":
  //           return formatIndianNumberSystem(input);
  //         case "est_asn_frt":
  //           return addSeparator(input, ",", 4);
  //         default:
  //           return input;
  //       }
  //     })(${num}, "${formatType}")
  //   `;

  //   return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  // };

  Blockly.JavaScript["format_number"] = function (block) {
    let formatType = block.getFieldValue("FORMAT");
    let num = Blockly.JavaScript.valueToCode(block, "NUMBER", Blockly.JavaScript.ORDER_ATOMIC);

    var code = `
      (function(input, formatType) {
        // Convert input to string if it's not already
        input = String(input).trim(); // Remove any surrounding whitespace

        // Function to remove all non-digit characters except decimal point
        function removeSeparators(str) {
          return str.replace(/[^\\d.]/g, "");
        }

        // Function to handle separators only for the integer part before the decimal point
        function formatWithSeparators(number, formatFunction) {
          const parts = number.split(".");  // Split the number by the decimal point
          const integerPart = parts[0];     // Integer part before the decimal point
          const decimalPart = parts[1] ? "." + parts[1] : "";  // Fractional part (if exists)

          // Apply the format function only to the integer part
          return formatFunction(integerPart) + decimalPart;  // Return formatted integer part and untouched fractional part
        }

        function formatNoSeparator(number) {
          return removeSeparators(number);  // No separators
        }

        function formatApostropheSeparator(number) {
          return formatWithSeparators(number, (num) => num.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1'"));
        }

        function formatSpaceSeparator(number) {
          return formatWithSeparators(number, (num) => num.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1 "));
        }

        function formatUnderscoreSeparator(number) {
          return formatWithSeparators(number, (num) => num.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1_"));
        }

        function formatWesternInternational(number) {
          return formatWithSeparators(number, (num) => num.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,"));
        }

        function formatEuropean(number) {
          return formatWithSeparators(number, (num) => num.replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1.").replace(/\\.(?=\\d)/, "."));
        }

        function formatIndianNumberSystem(number) {
          const parts = number.split(".");
          const integerPart = parts[0];
          const decimalPart = parts[1] ? "." + parts[1] : "";
          const firstGroup = integerPart.slice(-3);
          const remainingGroups = integerPart.slice(0, -3).replace(/(\\d)(?=(\\d{2})+(?!\\d))/g, "$1,");
          return remainingGroups + (remainingGroups ? "," : "") + firstGroup + decimalPart;
        }

        function formatEastAsian(number) {
          return formatWithSeparators(number, (num) => num.replace(/(\\d)(?=(\\d{4})+(?!\\d))/g, "$1,"));
        }

        // Handle number conversion if input is not NaN
        if (!isNaN(input)) {
          input = removeSeparators(input);  // Clean any previous separators
        }

        // Apply the correct formatting based on the selected format
        switch (formatType) {
          case "no_sep":
            return formatNoSeparator(input);
          case "aps_sep":
            return formatApostropheSeparator(input);
          case "spc_sep":
            return formatSpaceSeparator(input);
          case "spc_sep_underscore":
            return formatUnderscoreSeparator(input);
          case "wes_int_frt":
            return formatWesternInternational(input);
          case "eu_frt":
            return formatEuropean(input);
          case "ind_num_sys":
            return formatIndianNumberSystem(input);
          case "est_asn_frt":
            return formatEastAsian(input);
          default:
            return input;  // Default to returning the input unchanged
        }
      })(${num}, "${formatType}")
    `;

    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };


  Blockly.JavaScript['format_number_decimal'] = function(block) {
    let decimalPlaces = block.getFieldValue('DECIMAL_PLACES');
    let num = Blockly.JavaScript.valueToCode(block, 'NUMBER', Blockly.JavaScript.ORDER_ATOMIC);

    var code = `
      (function(input, decimalPlaces) {
        // Ensure input is treated as a string and trim any surrounding whitespace
        input = String(input).trim();

        // Function to handle decimal places
        function applyDecimalPlaces(number, decimalPlaces) {
          // Convert the number string to a float if it's a valid number
          let parsedNumber = parseFloat(number);
          if (isNaN(parsedNumber)) return number; // If NaN, return the original input

          // Handle rounding to the appropriate number of decimal places
          switch(decimalPlaces) {
            case "two_dec":
              return parsedNumber.toFixed(2);
            case "four_dec":
              return parsedNumber.toFixed(4);
            case "six_dec":
              return parsedNumber.toFixed(6);
            case "eight_dec":
              return parsedNumber.toFixed(8);
            default:
              return number;
          }
        }

        // Apply decimal places to the input
        return applyDecimalPlaces(input, decimalPlaces);
      })(${num}, "${decimalPlaces}")
    `;

    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  // to slice a list/array
  Blockly.JavaScript['slice_list'] = function(block) {
    var list = Blockly.JavaScript.valueToCode(block, 'LIST', Blockly.JavaScript.ORDER_ATOMIC);
    var start = Blockly.JavaScript.valueToCode(block, 'START', Blockly.JavaScript.ORDER_ATOMIC);
    var end = Blockly.JavaScript.valueToCode(block, 'END', Blockly.JavaScript.ORDER_ATOMIC);
    var index = Blockly.JavaScript.valueToCode(block, 'INDEX', Blockly.JavaScript.ORDER_ATOMIC);

    // Check if the INDEX input is provided
    if (index) {
        // Generate code for retrieving a single element at a specific index
        var code = `${list}[${index}]`;
    } else {
        // If end is not provided, use undefined to slice till the end
        if (!end) {
            end = 'undefined';
        }

        // Generate code for slicing the list
        var code = `${list}.slice(${start}, ${end})`;
    }

    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript['array_push'] = function(block) {
    var array = Blockly.JavaScript.valueToCode(block, 'ARRAY', Blockly.JavaScript.ORDER_ATOMIC);
    var value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_ATOMIC);

    if (!array) {
      array = '[]'; // Fallback in case the array input is not provided
    }

    var code = `${array}.push(${value})`; // Generate code that returns the new length
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['array_join'] = function(block) {
    var array = Blockly.JavaScript.valueToCode(block, 'ARRAY', Blockly.JavaScript.ORDER_ATOMIC);
    var separator = Blockly.JavaScript.valueToCode(block, 'SEPARATOR', Blockly.JavaScript.ORDER_ATOMIC);

    if (!separator) {
      separator = '","'; // Default separator is a comma if none is provided
    }

    var code = `${array}.join(${separator})`;
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  // mutation observer block to monitor changes in element tags
  Blockly.JavaScript['generalized_mutation_observer'] = function(block) {
    var elementId = block.getFieldValue('ELEMENT_ID');
    var attributesToMonitor = block.getFieldValue('ATTRIBUTES').split(',').map(attr => attr.trim());
    var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');
    var stopObserver = block.getFieldValue('STOP_OBSERVER') === 'TRUE';

    var code = `
      (function() {
        var targetNode = document.getElementById("${elementId}");
        if (!targetNode) {
          console.warn("Element with ID ${elementId} not found.");
          return;
        }

        var observerOptions = {
          childList: false,
          attributes: true,
          attributeFilter: ${JSON.stringify(attributesToMonitor)},
          subtree: false
        };

        var previousValues = {};

        var observerCallback = function(mutationsList, observer) {
          for (var mutation of mutationsList) {
            if (mutation.type === 'attributes' && ${JSON.stringify(attributesToMonitor)}.includes(mutation.attributeName)) {
              var newValue = targetNode.getAttribute(mutation.attributeName);
              var oldValue = previousValues[mutation.attributeName];

              if (newValue !== oldValue) {
                previousValues[mutation.attributeName] = newValue;
                
                ${statements_do}
                
                if (${stopObserver}) {
                  observer.disconnect();
                  console.log("Observer stopped.");
                }
                break;
              }
            }
          }
        };

        ${JSON.stringify(attributesToMonitor)}.forEach(attr => {
          previousValues[attr] = targetNode.getAttribute(attr);
        });

        var observer = new MutationObserver(observerCallback);
        observer.observe(targetNode, observerOptions);

        var reconnectObserver = function() {
          observer.observe(targetNode, observerOptions);
        };
        
        if (${!stopObserver}) {
          setTimeout(reconnectObserver, 0);
        }
      })();
    `;
    return code;
  };

  // block to get the attributes of a target DOM element
  Blockly.JavaScript['get_attributes'] = function(block) {
    var elementId = Blockly.JavaScript.valueToCode(block, 'ELEMENT_ID', Blockly.JavaScript.ORDER_ATOMIC);
    var attributeName = Blockly.JavaScript.valueToCode(block, 'ATTRIBUTE_NAME', Blockly.JavaScript.ORDER_ATOMIC);
    var code = `document.getElementById(${elementId}).getAttribute(${attributeName})`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  // block to set the attributes of a target DOM element
  Blockly.JavaScript['set_attributes'] = function(block) {
    var elementId = Blockly.JavaScript.valueToCode(block, 'ELEMENT_ID', Blockly.JavaScript.ORDER_ATOMIC);
    var attributeName = Blockly.JavaScript.valueToCode(block, 'ATTRIBUTE_NAME', Blockly.JavaScript.ORDER_ATOMIC);
    var attributeValue = Blockly.JavaScript.valueToCode(block, 'ATTRIBUTE_VALUE', Blockly.JavaScript.ORDER_ATOMIC);
    var code = `document.getElementById(${elementId}).setAttribute(${attributeName}, ${attributeValue});\n`;
    return code;
  };

  // Define the JavaScript code generation
  Blockly.JavaScript['clear_local_storage'] = function(block) {
      var code = 'localStorage.clear();\n'; // Local session storage
      return code; // No return value needed
  };

  // code generation block for generating nested iframe and document (so that storage event blocks can be put in them so that they work in download as storage events cannot run from same script or window document)
  Blockly.JavaScript['generate_iframe'] = function(block) {
    var allowFullscreen = block.getFieldValue('ALLOWFULLSCREEN') === 'TRUE' ? 'allowfullscreen' : '';
    var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');
    
    // Child ID for unique logic
    var childId = block.childId; // Assumes childId is available for blocks

    var code = `
      (function() {
        // Check if the current URL matches editor-related substrings
        let substring${childId} = window.location.href.includes("/editor/") &&
          window.location.href.includes("?projectId=") ||
          window.location.href.includes("/large_preview/") ||
          window.location.href.includes("/tab_preview/") ||
          window.location.href.includes("/mobile_preview/") ||
          window.location.href.includes("/fragment_editor/");

        // If the substring is true (meaning we are in the editor or preview mode)
        if (substring${childId}) {
          ${statements_do}  // Directly execute the statements without iframe
        } else {
          // Append the iframe when running outside of the editor environment
          const iframe = document.createElement('iframe');
          iframe.setAttribute('allowfullscreen', '${allowFullscreen}');
          iframe.setAttribute('class', 'gjs-frame');

          // Create the nested document structure
          const nestedDocument = document.implementation.createHTMLDocument('Nested Document');
          const head = nestedDocument.head; // Get the existing head
          const body = nestedDocument.body; // Get the existing body

          // Create a script element for the DO statements
          const script = nestedDocument.createElement('script');
          script.textContent = \`${statements_do}\`; // Set the JavaScript code as text

          // Append the script to the body of the nested document
          body.appendChild(script);

          // Append the iframe to the current document body
          document.body.appendChild(iframe);

          // Access the iframe's content document and write the nested document into it
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(nestedDocument.documentElement.outerHTML);
          iframeDoc.close();
        }
      })();
    `;

    return code;
  };

  // Define the JavaScript code generation
  Blockly.JavaScript['clear_session_storage'] = function(block) {
      var code = 'sessionStorage.clear();\n'; // Clear session storage
      return code; // No return value needed
  };

  // blocks to listen to storage events in local/session storage | Note: always attach this under 'generate_iframe' block to make it work in download as well
  Blockly.JavaScript['storage_event_handler'] = function(block) {
    var statements_do = Blockly.JavaScript.statementToCode(block, 'DO');

    var code = `
      (function() {
        // Check if the current URL matches editor-related substrings
        let substring = window.location.href.includes("/editor/") &&
          window.location.href.includes("?projectId=") ||
          window.location.href.includes("/large_preview/") ||
          window.location.href.includes("/tab_preview/") ||
          window.location.href.includes("/mobile_preview/") ||
          window.location.href.includes("/fragment_editor/");

        // If the substring is true (meaning we are in the editor or preview mode)
        if (substring) {
          // Directly listen to the storage event in the current window
          window.addEventListener('storage', function(event) {
            ${statements_do}
          });
        } else {
          // When running outside the editor environment, append an iframe and handle the storage event in the nested document
          const iframe = document.createElement('iframe');
          iframe.setAttribute('allowfullscreen', 'true');
          iframe.setAttribute('class', 'gjs-frame');

          // Create the nested document structure
          const nestedDocument = document.implementation.createHTMLDocument('Nested Document');
          const head = nestedDocument.head; // Get the existing head
          const body = nestedDocument.body; // Get the existing body

          // Create a script element for the storage event listener
          const script = nestedDocument.createElement('script');
          script.textContent = \`window.addEventListener('storage', function(event) {
            ${statements_do}
          });\`; // Set the JavaScript code as text

          // Append the script to the body of the nested document
          body.appendChild(script);

          // Append the iframe to the current document body
          document.body.appendChild(iframe);

          // Access the iframe's content document and write the nested document into it
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(nestedDocument.documentElement.outerHTML);
          iframeDoc.close();
        }
      })();
    `;
    
    return code;
  };

  Blockly.JavaScript['storage_event_storage_area'] = function(block) {
    var code = 'event.storageArea === localStorage ? "localStorage" : "sessionStorage"';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript['storage_event_new_value'] = function(block) {
    var code = 'event.newValue';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript['storage_event_old_value'] = function(block) {
    var code = 'event.oldValue';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript['storage_event_key'] = function(block) {
    var code = 'event.key';
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  };

  Blockly.JavaScript['get_parent_id'] = function(block) {
    var id = Blockly.JavaScript.valueToCode(block, 'ID', Blockly.JavaScript.ORDER_ATOMIC);
    var code = `
      (function() {
        var element = document.getElementById(${id});
        return element && element.parentNode ? element.parentNode.id : 'null';
      })()
    `;
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['convert_to_base64'] = function (block) {
    const data = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_ATOMIC) || '""';

    const code = `
      (function() {
        const input = ${data};
        const encoded = typeof input === 'object' 
                        ? btoa(JSON.stringify(input)) 
                        : btoa(String(input));
        return encoded;
      })()
    `;
    
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['decode_from_base64'] = function (block) {
    const base64String = Blockly.JavaScript.valueToCode(block, 'BASE64', Blockly.JavaScript.ORDER_ATOMIC) || '""';

    const code = `
      (function() {
        const decodedString = atob(${base64String});
        try {
          // Check if it's a valid JSON string
          const parsed = JSON.parse(decodedString);
          if (typeof parsed === 'object' && parsed !== null) {
            return parsed; // Return the parsed object or array
          }
          return decodedString; // If not an object, return as a plain string
        } catch (e) {
          // Return the decoded string as is, if not a valid JSON
          return decodedString;
        }
      })()
    `;
    
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
  };

  Blockly.JavaScript['url_encode_decode'] = function(block) {
    const action = block.getFieldValue('ACTION'); // Get the selected action (encode or decode)
    const urlString = Blockly.JavaScript.valueToCode(block, 'URL_STRING', Blockly.JavaScript.ORDER_ATOMIC) || '""'; // Get the URL string input, default to an empty string

    let code;
    if (action === "ENCODE") {
      code = `encodeURIComponent(${urlString})`; // Encode the URL
    } else {
      code = `decodeURIComponent(${urlString})`; // Decode the URL
    }

    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL]; // Return the code with correct order
  };

  Blockly.JavaScript['generate_md5'] = function(block) {
    var message = Blockly.JavaScript.valueToCode(block, 'MESSAGE', Blockly.JavaScript.ORDER_ATOMIC);
    var code = `md5(${message})`;
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript['verify_md5'] = function(block) {
    var message = Blockly.JavaScript.valueToCode(block, 'MESSAGE', Blockly.JavaScript.ORDER_ATOMIC);
    var hash = Blockly.JavaScript.valueToCode(block, 'HASH', Blockly.JavaScript.ORDER_ATOMIC);
    var code = `md5(${message}) === ${hash}`;
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.JavaScript['aes_256_keygen'] = function(block) {
    var doStatements = Blockly.JavaScript.statementToCode(block, 'DO'); // Get the code from the DO block

    var code = `
      // AES 256 Key Generation
      (async function() {
        try {
          const key = await window.crypto.subtle.generateKey(
            {
              name: "AES-GCM",
              length: 256
            },
            true, // Exportable key
            ["encrypt", "decrypt"]
          );

          // Export the key in raw format
          const rawKey = await window.crypto.subtle.exportKey("raw", key);

          // Convert raw key to a hexadecimal string
          const keyArray = Array.from(new Uint8Array(rawKey));
          const keyHex = keyArray.map(b => b.toString(16).padStart(2, '0')).join('');

          // Store keyHex in sessionStorage
          sessionStorage.setItem('aes256_key', keyHex);

          // Execute any additional logic provided in the DO block
          ${doStatements}

        } catch (error) {
          console.error('Key generation failed:', error);
        }
      })();
    `;
    
    return code;
  };

  Blockly.JavaScript['aes_256_encrypt'] = function(block) {
    var plaintext = Blockly.JavaScript.valueToCode(block, 'PLAINTEXT', Blockly.JavaScript.ORDER_ATOMIC);
    var keyHex = Blockly.JavaScript.valueToCode(block, 'KEY', Blockly.JavaScript.ORDER_ATOMIC);
    var doStatements = Blockly.JavaScript.statementToCode(block, 'DO'); // Get the code from the DO block

    var code = `
      (async function() {
        try {
          const encodedText = new TextEncoder().encode(${plaintext});

          // Convert the hexadecimal key back to a Uint8Array for encryption
          const keyArray = new Uint8Array(${keyHex}.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
          
          // Import the key for use in encryption
          const key = await window.crypto.subtle.importKey(
            "raw",
            keyArray,
            {
              name: "AES-GCM",
            },
            true,
            ["encrypt"]
          );

          const iv = window.crypto.getRandomValues(new Uint8Array(12));
          const encryptedData = await window.crypto.subtle.encrypt(
            {
              name: "AES-GCM",
              iv: iv
            },
            key,
            encodedText
          );

          // Convert encrypted data and IV to arrays for storage
          const ivArray = Array.from(iv);
          const encryptedArray = Array.from(new Uint8Array(encryptedData));

          // Store in sessionStorage
          sessionStorage.setItem('aes256_encrypted_data', JSON.stringify({
            iv: ivArray,
            encryptedData: encryptedArray
          }));

          // Execute any additional logic provided in the DO block
          ${doStatements}
          
        } catch (error) {
          console.error('Encryption failed:', error);
          sessionStorage.setItem('aes256_encrypted_data', null);  // Store null in case of failure
        }
      })();
    `;

    return code;
  };


  Blockly.JavaScript['aes_256_decrypt'] = function(block) {
    var encryptedObj = Blockly.JavaScript.valueToCode(block, 'ENCRYPTED_OBJ', Blockly.JavaScript.ORDER_ATOMIC);
    var keyHex = Blockly.JavaScript.valueToCode(block, 'KEY', Blockly.JavaScript.ORDER_ATOMIC);
    var doStatements = Blockly.JavaScript.statementToCode(block, 'DO'); // Get the code from the DO block

    var code = `
      (async function() {
        try {
          const iv = new Uint8Array(${encryptedObj}.iv);
          const encryptedData = new Uint8Array(${encryptedObj}.encryptedData);

          // Convert the hexadecimal key back to a Uint8Array for decryption
          const keyArray = new Uint8Array(${keyHex}.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

          // Import the key for use in decryption
          const key = await window.crypto.subtle.importKey(
            "raw",
            keyArray,
            {
              name: "AES-GCM",
            },
            true,
            ["decrypt"]
          );

          const decryptedData = await window.crypto.subtle.decrypt(
            {
              name: "AES-GCM",
              iv: iv
            },
            key,  // Use the imported CryptoKey here
            encryptedData
          );

          const plaintext = new TextDecoder().decode(decryptedData);  // Decode the decrypted data

          // Optionally store the plaintext in sessionStorage or perform other actions
          sessionStorage.setItem('aes256_plaintext', plaintext);
          
          // Execute any additional logic provided in the DO block
          ${doStatements}          
        } catch (error) {
          console.error('Decryption failed:', error);
          sessionStorage.setItem('aes256_plaintext', null);  // Store null in case of failure
        }
      })();
    `;

    return code;
  };

  // redirection block that redirects automatically to a given url/link
  Blockly.JavaScript['redirect_to_url'] = function (block) {
    // Get the URL input value
    var url = Blockly.JavaScript.valueToCode(block, 'URL', Blockly.JavaScript.ORDER_ATOMIC);

    // Get the checkbox field value (TRUE or FALSE)
    var autoRedirect = block.getFieldValue('AUTO_REDIRECT') === 'TRUE';

    // Generate the code for redirection based on checkbox state
    var code = '';

    if (autoRedirect) {
      code = `if (${url}) {
        window.location.href = ${url};
      }`;
    } else {
      code = `console.log("Auto redirect is disabled. URL:", ${url});`;
    }

    return code + '\n';
  };

  // triggers events on the target element programmatically whether they are present in nested iframe doms or showdow doms
  Blockly.JavaScript['trigger_browser_event'] = function(block) {
    const eventType = block.getFieldValue('EVENT_TYPE');
    const targetId = Blockly.JavaScript.valueToCode(block, 'TARGET_ID', Blockly.JavaScript.ORDER_ATOMIC);

    const code = `
      (function() {
        function findElementInNestedDocuments(doc, targetId) {
          // Check the current document
          var targetElement = doc.getElementById(targetId);
          if (targetElement) return targetElement;

          // Search in iframes
          var iframes = doc.getElementsByTagName('iframe');
          for (var i = 0; i < iframes.length; i++) {
            try {
              var iframeDoc = iframes[i].contentDocument || iframes[i].contentWindow.document;
              targetElement = findElementInNestedDocuments(iframeDoc, targetId);
              if (targetElement) return targetElement;
            } catch (e) {
              console.warn('Error accessing iframe:', e);
            }
          }

          // Search in shadow DOM
          var allElements = doc.getElementsByTagName('*');
          for (var j = 0; j < allElements.length; j++) {
            if (allElements[j].shadowRoot) {
              targetElement = findElementInNestedDocuments(allElements[j].shadowRoot, targetId);
              if (targetElement) return targetElement;
            }
          }

          return null; // Element not found in this document or any nested documents
        }

        var targetElement = findElementInNestedDocuments(document, ${targetId});
        if (targetElement) {
          try {
            var event = new Event('${eventType}');
            targetElement.dispatchEvent(event);
            console.log('Event "${eventType}" triggered on', targetElement);
          } catch (error) {
            console.error('Error triggering event:', error);
          }
        } else {
          console.warn('Element with ID', ${targetId}, 'not found in any nested document.');
        }
      })();
    `;
    
    return code;
  };


  return Blockly.JavaScript;
};
